import React, { useEffect, useState } from 'react';

import { Col, Row, Container, Alert, Button } from 'react-bootstrap';

import SelectItemComponent from '../selectItem/SelectItemComponent.jsx';
import SelectedMediaListComponent from '../SelectedMediaList/SelectedMediaListComponent.jsx';
import HelperMetiers360 from "../../../services/HelpersMetiers360.js";


const mediaStructureCallBack  = (media) => {
    let formatedObject = {};

    if ('videos' in media || 'quizzes' in media) {
        formatedObject = {
            uniqueId:media.uniqueId,
            name:media.name || media.videoName || media.headsetName,
            thumbnail: media.links ? (media.links.thumbnail || media.links.image)  :media.thumbnail ,    
            videos: media.videos,
            quizzes: media.quizzes
        }
    }

    if ('typeOfStory' in media) {
        formatedObject = {
            id: media.id,
            uniqueId:media.uniqueId,
            name:media.videoName ? media.videoName: media.name,
            thumbnail: media.thumbnail ,
        }    
    }

    else {
        formatedObject = {
            id: media.id,
            uniqueId: media.uniqueId,
            name: media.name || media.videoName || media.headsetName || media.deviceId,
            thumbnail: media.links ? (media.links.thumbnail || media.links.image) : media.thumbnail,
        }
    };

    media.subHeading && (formatedObject.subHeading = media.subHeading);
    media.description && (formatedObject.description = media.description);
    media.romes && (formatedObject.romes = media.romes);
    media.producers && (formatedObject.producers = media.producers);
    media.isDirectlyAccessible && media.directAccessCode && (formatedObject.directAccessCode = media.directAccessCode);

    return formatedObject;
}


const mediaSearchCallBack = (media, mediaType, search) => {
    if (mediaType === "video") {
        return media.filter(
            listItem => HelperMetiers360.isSearchInText(search, listItem.name)
                || HelperMetiers360.isSearchInText(search, listItem.subHeading)
                || HelperMetiers360.isSearchInText(search, listItem.description)
                || HelperMetiers360.isSearchInText(search, listItem.romes?.join())
                || HelperMetiers360.isSearchInText(search, listItem.producers
                    ?.map(producer => producer.clientName)?.join())
                || HelperMetiers360.isSearchInText(search, listItem.directAccessCode)
        )
    }
    else {
        return media.filter(
            listItem => HelperMetiers360.isSearchInText(search, listItem.name)
        )
    }
}


const titleLookup = {
    video: 'expériences immersives',
    quiz: 'quiz',
    module: 'modules pédagogiques',
    group: 'groupes',
    story: 'stories',
    headset: 'casques'
};

/**
 * SelectMediaModuleComponent is a component that handles a Selection from a list of media (stories/videos/modules/quiz/groups). It handles the display and logic of the selection part as well as the selected part.
 * note: the selection part will be displayed as a card board with infinite laoding on scroll inside a scrollable div
 * 
 * @param {(array)} mediaResultRef the result list of the selected items 
 * @param {array} allMedias the original list of medias to pick from
 * @param {callback} onChange [default value null] callback used in the special case of the pedagogic modules in group form to handle the auto filling of the videos/quizzes  in group content on module selection.
 * @param {number} maxMediaToDisplay [default value 10] - the number of element to be displayed for the selection part (with infinite loading)
 * @param {bool} draggable [default value true] - if draggable true,  the selected part will be a list of items draggable and reordables, else it will be displayed as another card board
 * @param {string} mediaType type of media used ['story'|'module'|'video'|'quiz'|'group']
 * @param {function} setRealValue function used to handle the real value of medias selection, intermediate variable used to not change original value mediaResultRef until the form is validated
 * 
 */


const SelectMediaModuleComponent = (props) => {
    let { mediaResultRef, allMedias, onChange = null, maxMediasToDisplay = 10, draggable = true, mediaType, setRealValue = undefined,
        onlySummaryInfos = false
    } = props;
    const [selectedMedias, setSelectedMedias] = useState([]);

    const [medias, setMedias] = useState([]);

    useEffect(() => {
        setMedias(mediaResultRef.current.length > 0
            ? allMedias?.filter((media) => !mediaResultRef?.current?.find(selectedMedia => selectedMedia.uniqueId === media.uniqueId))
            : allMedias);
        setSelectedMedias(mediaResultRef.current);
    }, [mediaResultRef.current]);


    useEffect(() => {
        setRealValue !== undefined && setRealValue(selectedMedias);
    }, [selectedMedias, mediaResultRef])

    const onSelectMedia = (selectedUniqueId) => {
        const selection = [...selectedMedias,...medias.filter((media) => media.uniqueId === selectedUniqueId)]
        setSelectedMedias(selection);
        setMedias([
            ...medias.filter((media) => media.uniqueId !== selectedUniqueId)
        ]);
        if (onChange)
            onChange(selection);

        if(setRealValue === undefined)
            mediaResultRef.current = selection;
    };

    const removeMedia = (mediaUniqueId) => {
        const clickedMedia = selectedMedias.find(media => media.uniqueId === mediaUniqueId);
        const selection = [...selectedMedias.filter(media => media.uniqueId !== mediaUniqueId)];
        setSelectedMedias(selection)

        if (onChange)
            onChange(selection);

        setMedias([clickedMedia, ...medias]);

        if(setRealValue === undefined)
            mediaResultRef.current = selection;
    };

    const reorderMedias = (list) => {
        setSelectedMedias([...list]);
    }

    const onUnselectAllMedias = () => {
        setSelectedMedias([]);
        setMedias(allMedias);
    }

    const onSelectAllMedias = (filteredResult) => {
        if (filteredResult) {
            const filteredReusltUUIds = filteredResult.map(result => result.uniqueId);
            setSelectedMedias([...selectedMedias, ...filteredResult]);
            setMedias(medias.filter(media => !filteredReusltUUIds.includes(media.uniqueId)));
        } else {
            setSelectedMedias(allMedias);
            setMedias([]);
        }
    }

    const getMediaTitle = () => {
        return titleLookup[mediaType]
    }


    return <Container>
        <Row>
            <Col lg={6}>
                { medias.length === 0
                    ? <Alert variant="info">Vous n'avez pas d'élément disponible</Alert>
                    : <SelectItemComponent list={medias.map(mediaStructureCallBack)} searchTitle="Rechercher par mots-clés (nom...)"
                        mediaSearchCallBack={mediaSearchCallBack} onSelect={onSelectMedia} maxVisibleItem={maxMediasToDisplay}
                        mediaType={mediaType} displayType="cards" onSelectAll={onSelectAllMedias} onlySummaryInfos={onlySummaryInfos} />
                }
            </Col>
            <Col className="draggable-list-group mb-4">
                <h3 className="d-flex align-items-center ">
                    <i className="fas fa-shopping-basket mr-2" style={{fontSize: "2rem"}}/>
                    <span>Sélection de {selectedMedias.length + " " + getMediaTitle()}</span>
                </h3>
                    
                {selectedMedias.length === 0
                    ? <>Cliquer sur un élément pour l'ajouter à la liste.</>
                    : <>
                        Cliquer sur un élément pour l'enlever de la liste.
                        {draggable &&  <> Drag'n'drop pour modifier l'ordre des éléments.</>}
                        <Row className='d-flex justify-content-center my-2'>
                            <Button variant='danger' className='w-auto' onClick={() => onUnselectAllMedias()}>
                                Tout désélectionner
                            </Button>
                        </Row>
                        <SelectedMediaListComponent
                            draggable={draggable}
                            mediaType={mediaType}
                            mediaList={selectedMedias.map(mediaStructureCallBack)} 
                            onClickItem={removeMedia} 
                            setList={setSelectedMedias}
                            onReorderList={reorderMedias}
                            onlySummaryInfos={onlySummaryInfos} />
                    </>
                }
            </Col>
        </Row>
    </Container>;
}

export default SelectMediaModuleComponent;
