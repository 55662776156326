import React, { useEffect, useState } from 'react';
import { Page, Text, View, Document, StyleSheet, usePDF, Image } from '@react-pdf/renderer';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

import logo from '../../../images/logo_changeons_point_vue.png';
import ButtonLoaderComponent from '../ButtonLoaderComponent';

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontWeight: 'light',
        fontSize: 12,
        padding: 40,
        height: '100%'
    },
    todayDate: {
        fontSize: 10,
        marginBottom: 5,
        marginTop: -15
    },
    sectionlogo: {
        width: '70%',
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'end',
    },
    logo: {
        width: 60,
        borderRadius: '50%',
    },
    titleView: {
        width: 1200
    },
    title: {
        fontSize: 22,
        fontFamily: 'Helvetica-Bold',
        marginBottom: 20,
    },
    row: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 10
    },
    mosaic: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginLeft: 6,
        marginRight: 6,
        marginBottom: 40,
        padding: 0,
    },
    card: {
        width: 113,
        margin: 6,
        padding: 0,
        height: 63,
    },
    spaceBetween: {
        justifyContent: 'space-between'
    },
    flexEnd: {
        alignItems: 'flex-end',
    }
})

const dateString = new Date().toLocaleDateString('fr-FR');

const GroupThumbnailsContentDocument = ({ group, items }) => {
    return <Document title='contenu-vignettes-espace-immersif' author='Métiers360'>
        <Page size="A4" style={styles.page}>
            <View style={[styles.row, styles.spaceBetween]}>
                <View style={styles.titleView}>
                    <Text style={styles.title}>{group.name}</Text>
                </View>

                <View style={[styles.sectionlogo, styles.flexEnd]}>
                    <Text style={styles.todayDate}>A jour du {dateString}</Text>
                    <Image source={logo} style={styles.logo} />
                </View>
            </View>

            <View style={[styles.mosaic]}>
                // TODO : Replace `items` by `group?.items` when back will return only content accessible by user connected
                {items
                    ?.sort((a, b) => a.displayOrder - b.displayOrder)
                    ?.map((item) =>
                        <View key={item.uniqueId}>
                            <Image source={item.thumbnail + "?"} style={styles.card} />
                        </View>
                    )}
            </View>
        </Page>
    </Document>;

};

const DownloadGroupThumbnailsContentPDF = ({ group, items, buttonText, fileName }) => {

    const [downloadDone, setDownloadDone] = useState(false);
    const [instance] = usePDF({ document: <GroupThumbnailsContentDocument group={group} items={items} /> });

    useEffect(() => {
        if (!instance.loading && group && !downloadDone) {
            setDownloadDone(true);
            const link = document.createElement('a');
            link.href = instance.url;
            link.download = `${fileName}-${dateString}.pdf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }, [instance.loading, group, downloadDone]);

    const isLoading = (instance.loading && !instance.url) || !group;

    return instance.error
        ? <Button variant="danger" ><i className="fas fa-exclamation-triangle mr-1" />{buttonText}</Button>
        : <ButtonLoaderComponent variant="primary" isSending={isLoading} disabled={isLoading} id="btn-pdf-videos-by-group"
            href={instance.url} download={`${fileName}-${dateString}.pdf`}
            iconClassName="fas fa-download" msg={buttonText} />;
};

GroupThumbnailsContentDocument.propTypes = {
    group: PropTypes.object.isRequired,
    items: PropTypes.array.isRequired
};
DownloadGroupThumbnailsContentPDF.propTypes = {
    group: PropTypes.object.isRequired,
    buttonText: PropTypes.string.isRequired,
    fileName: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired
};

export default DownloadGroupThumbnailsContentPDF;
