import { Col, Row } from "react-bootstrap";
import './VideoDetailsAudience.scss';
import PropTypes from 'prop-types';
import GraphVideosComponent from "../../producerStats/GraphVideosComponent";
import AuthorizationChecker from "../../../services/AuthorizationChecker";

const VideoDetailsAudience = ({ videoUniqueId }) => {
    const toggleGeoMap = [
        { label: "Régions", value: "regions", mapType: "regions", defaultChecked: true },
        { label: "Départements", value: "departments", mapType: "departments", defaultChecked: false }
    ];
    const toggleMeanViewsDuration = [
        { label: "Pourcentage", value: "percent", graphType: "meanViewsDurationPercent", defaultChecked: true },
        { label: "Nombre", value: "number", graphType: "meanViewsDuration", defaultChecked: false }
    ];

    return <>
        <Row>
            <Col md={12} lg={AuthorizationChecker.isAdmin() ? 6 : 12} className="graph-container">
                <GraphVideosComponent graphType="chronogram" videosUuid={[videoUniqueId]} height="40vh" />
            </Col>
            {AuthorizationChecker.isAdmin() && <Col md={12} lg={6} className="graph-container">
                <GraphVideosComponent graphType="meanViewsDurationPercent" videosUuid={[videoUniqueId]} height="40vh" toggleData={toggleMeanViewsDuration} />
            </Col>}
        </Row>
        <Row className="mt-4">
            <Col md={12} lg={6} className="graph-container">
                <GraphVideosComponent graphType="clientCategories" videosUuid={[videoUniqueId]} height="100vh" width="100%" />
            </Col>
            <Col md={12} lg={6} className="graph-container">
                <GraphVideosComponent graphType="geoMap" videosUuid={[videoUniqueId]} toggleData={toggleGeoMap} height="80vh" />
            </Col>
        </Row>
    </>;
}

VideoDetailsAudience.propTypes = {
    videoUniqueId: PropTypes.string.isRequired,
}

export default VideoDetailsAudience;