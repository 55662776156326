import React from "react";
import Plot from 'react-plotly.js';
import useCheckDataProps from '../../hooks/useCheckDataProps';
import { Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';

const RANGE_VALUE = 10;

const MeanViewsDurationPercent = ({ data = [], aggregated, height = "100%" }) => {
    const [proxiedData, isValid] = useCheckDataProps(data || []);


    const getPercentRange = (percentage) => {
        let rangeStart = Math.floor(percentage / 10) * 10;
        if (rangeStart >= 100) { rangeStart = 90 }; // correction car certains events montrent des durées de lecture > durée de la vidéo (personne ayant mis du temps à quitter la vidéo?)
        const rangeEnd = rangeStart + 9;
        return `${rangeStart}-${rangeEnd}`;
    };

    const generateEmptyRange = (rangeValue) => {
        const emptyRange = {};
        for (let i = 0; i < 100; i += rangeValue) {
            const rangeStart = i;
            const rangeEnd = i + rangeValue - 1;
            const rangeKey = `${rangeStart}-${rangeEnd}`;
            emptyRange[rangeKey] = { x: rangeKey, y: 0 };
        }
        return emptyRange;
    };

    const generateGroupedData = (proxiedData, aggregated) => {
        const emptyRange = generateEmptyRange(RANGE_VALUE);
        if (aggregated) {
            const aggregatedData = proxiedData.reduce((acc, item) => {
                const range = getPercentRange(item.playback_percentage);
                if (!acc[range]) {
                    acc[range] = { ...emptyRange[range] };
                }
                acc[range].y += item.views_percentage;
                return acc;
            }, { ...emptyRange });
            const x = Object.keys(aggregatedData).sort((a, b) => parseInt(a.split('-')[0]) - parseInt(b.split('-')[0]));
            const y = x.map(range => aggregatedData[range].y);

            return [{
                x,
                y,
                type: 'scatter',
                mode: 'lines+markers',
                marker: { color: 'red' },
                name: 'Total Views',
            }];
        } else {
            const groupedData = proxiedData.reduce((acc, item) => {
                const range = getPercentRange(item.playbac_percentage);
                if (!acc[item.video_id]) {
                    acc[item.video_id] = { ...emptyRange };
                }
                if (!acc[item.video_id]?.[range]?.video_name) {
                    acc[item.video_id][range] = {
                        x: range,
                        y: 0,
                        video_name: item.video_name,
                        totalLength: 100
                    };
                }
                acc[item.video_id][range].y += item.views_percentage;
                return acc;
            }, {});

            return Object.values(groupedData).map(videoData => {
                const x = Object.keys(videoData)
                    .sort((a, b) => parseInt(a.split('-')[0]) - parseInt(b.split('-')[0]));
                const y = x.map(range => videoData[range].y);

                return {
                    x,
                    y,
                    type: 'scatter',
                    mode: 'lines+markers',
                    name: videoData[x[0]]?.video_name,
                };
            });
        }
    };

    const traces = generateGroupedData(proxiedData, aggregated);

    return data?.length > 0 ?
        isValid ?
            <Plot
                data={traces}
                layout={{
                    autosize: true,
                    xaxis: {
                        title: {
                            text: 'Durées de lecture (en %)',
                            font: {
                                size: 14,
                                color: '#7f7f7f',
                            },
                        },
                    },
                    yaxis: {
                        title: {
                            text: 'Nombre de lecture (en %)',
                            font: {
                                size: 14,
                                color: '#7f7f7f',
                            },
                            standoff: 60,
                        },
                        range: [0, 100],
                    },
                    margin: {
                        l: 50,
                        r: 50,
                        b: 50,
                        t: 50,
                        pad: 4,
                    },
                }}
                useResizeHandler={true}
                style={{ width: '100%', height, minHeight: '30em' }}
            />
            : <Alert variant="danger">Les données ne correspondent pas</Alert>
        : <Alert variant="warning">Aucune donnée à afficher</Alert>;
}

MeanViewsDurationPercent.propTypes = {
    data: PropTypes.array.isRequired,
    aggregated: PropTypes.bool.isRequired,
    height: PropTypes.string,
}

export default MeanViewsDurationPercent;
