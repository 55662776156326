import React from "react";

import { CardGroup } from 'react-bootstrap';
import Loader from "../Loader";
import MiniCardMediaComponent from "../miniCardMedia/MiniCardMediaComponent";

import './CardGroupMediaComponent.scss';

/**
 * 
 * @param {string} mediaType string precising which type of media we will display ['quiz' | 'story' | 'sequence' | 'pedagogicModule']
 * @param {Array} mediaList the list of all medias to display in an array
 * @param {string} readOne router slug to access the readOne page of the media
 * @param {bool} isLink boolean to specify if group should be treated as a link or not - optionnal, default value to true
 * @param {callback} onClickItem you should receive this callback only when cardGroupMedia is used by selectedMediaListComponent
 * 
 */
const CardGroupMediaComponent = (props) => {
    const { mediaType, mediaIsLoading, mediaList, readOne: readOneSlug, onClickItem = null, isLink = true, onlySummaryInfos = false, nbCol = "auto", ...other } = props;

    const mediaCards = mediaList
        .map(media => {
            return (
                <div className={`${mediaType}-card col-${nbCol}`} key={media.uniqueId}>
                    <MiniCardMediaComponent 
                        media={media} 
                        mediaType={mediaType} 
                        isLink={isLink} 
                        readOneSlug={readOneSlug ? readOneSlug.replace(':uniqueId', media.uniqueId): null} 
                        onClickItem={onClickItem} 
                        onlySummaryInfos={onlySummaryInfos}
                        {...other} />
                </div>
            )
        });

    return (
        
        <CardGroup className="card_group_media">
            { mediaIsLoading ? <Loader/> : mediaCards}
        </CardGroup>
    );
}
export default CardGroupMediaComponent;