import React, { useEffect, useMemo, useState } from "react";
import Loader from "../../components/widgets/Loader";
import useCustomGetStoreState from "../../hooks/useCustomGetStoreState";
import { Alert, Col, Form, Row, Tab, Tabs, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import { useStoreActions, useStoreState } from "easy-peasy";
import SelectSingleClientComponent from "../../components/widgets/selectClient/SelectSingleClientComponent";
import axios from "../../services/axios";
import CustomSelectSingleComponent from "../../components/widgets/CustomSelectComponent/CustomSelectSingleComponent";
import CardGroupMediaComponent from "../../components/widgets/CardGroupMedia/CardGroupMediaComponent";
import { useModal } from "../../hooks/useModal";
import DynamicTableInfinite from "../../components/widgets/dynamicTableInfinite/DynamicTableInfinite";
import useInfiniteLoadingLogic from "../../hooks/useInfiniteLogic";
import MiniCardMediaComponent from "../../components/widgets/miniCardMedia/MiniCardMediaComponent";
import HelperMetiers360 from "../../services/HelpersMetiers360";

const ChoicesTestPage = () => {
    const { allClients, isFetchingAllClients } = useCustomGetStoreState("clients");
    const { allAreasOfInterest, isFetchingAllAreasOfInterest, allLargeProfessionalFields, isFetchingAllLargeProfessionalFields } = useStoreState(state => state.romes);
    const { fetchAllAreasOfInterest, fetchAllLargeProfessionalFields } = useStoreActions(actions => actions.romes);
    const { apiData: { ACTIONS } } = useStoreState(state => state.actionSlugs);
    const { readOne: readOneVideo } = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('videos'));
    const { modalComponent, setModalShow, modalData, setModalData } = useModal();

    const [sortedCombinations, setSortedCombinations] = useState([]);
    const [combinationsToDisplay, setCombinationsToDisplay] = useState([]);
    const [shouldSortCombinations, setShouldSortCombinations] = useState(false);
    const { hasMore: hasMoreCombinations, fetchNext: fetchDataCombinations } = useInfiniteLoadingLogic(sortedCombinations, combinationsToDisplay, setCombinationsToDisplay, 100);
    const [sortedPossibleRoutes, setSortedPossibleRoutes] = useState([]);
    const [possibleRoutesToDisplay, setPossibleRoutesToDisplay] = useState([]);
    const { hasMore: hasMorePossibleRoutes, fetchNext: fetchDataPossibleRoutes } = useInfiniteLoadingLogic(sortedPossibleRoutes, possibleRoutesToDisplay, setPossibleRoutesToDisplay, 100);
    const [shouldSortPossibleRoutes, setShouldSortPossibleRoutes] = useState(false);

    const [selectedClient, setSelectedClient] = useState([]);
    const [webAppAccessCode, setWebAppAccessCode] = useState();
    const [videos, setVideos] = useState([]);
    const [isAdminView, setIsAdminView] = useState(true);
    const [selectedEntity1, setSelectedEntity1] = useState([]);
    const [selectedEntity2, setSelectedEntity2] = useState([]);
    const [selectedEntity3, setSelectedEntity3] = useState([]);
    const [key, setKey] = useState('combinations');
    const [isFetching, setIsFetching] = useState(false);
    const [filterValue, setFilterValue] = useState("");
    const [filteredCombinations, setFilteredCombinations] = useState([]);
    const [filteredPossibleRoutes, setFilteredPossibleRoutes] = useState([]);
    const [maxVideosInCommon, setMaxVideosInCommon] = useState(6);
    const [entityToAnalyse, setEntityToAnalyse] = useState('areasOfInterest');

    const admin360Client = allClients?.find(client => client.clientName === "Métiers 360 - TEST");

    useEffect(() => {
        if (!isFetchingAllAreasOfInterest && allAreasOfInterest.length === 0) {
            fetchAllAreasOfInterest();
        } 
        if (!isFetchingAllLargeProfessionalFields && allLargeProfessionalFields.length === 0) {
            fetchAllLargeProfessionalFields();
        }
    }, [])

    useEffect(() => {
        setSortedCombinations([...filteredCombinations]);
        setShouldSortCombinations(true);
    }, [filteredCombinations])

    useEffect(() => {
        if (filteredPossibleRoutes?.length > 0) {
            setSortedPossibleRoutes([...filteredPossibleRoutes]);
            setShouldSortPossibleRoutes(true);
        }
    }, [filteredPossibleRoutes])

    const allEntities = useMemo(() => {
        return entityToAnalyse === "areasOfInterest" ? allAreasOfInterest : allLargeProfessionalFields;
    }, [entityToAnalyse, allAreasOfInterest, allLargeProfessionalFields])

    const entityName = useMemo(() => {
        return entityToAnalyse === "areasOfInterest" ? "centres d'intérêt" : "domaines professionnels";
    }, [entityToAnalyse])

    const entityNameSingular = useMemo(() => {
        return entityToAnalyse === "areasOfInterest" ? "centre d'intérêt" : "domaine professionnel";
    }, [entityToAnalyse])

    const fetchWebAppAccess = (clientUniqueId) => {
        const getUrl = ACTIONS.clients?.getProps?.getWebappAccesses?.url?.replace('uniqueId', clientUniqueId);
        axios.get(getUrl)
            .then(response => {
                setWebAppAccessCode(response.length > 0 && response[0]?.code
                    ? response[0]?.code
                    : undefined);
            })
    }

    const fetchVideos = (webAppCode) => {
        setIsFetching(true);
        const getUrl = ACTIONS?.videos?.getVideosLikeWebapp?.url;
        const formData = new FormData();
        formData.set('webAppCode', webAppCode);

        axios.post(getUrl, formData)
            .then(response => {
                setVideos(response.map(video => {
                    return {
                        largeProfessionalFields: [...new Set(video?.romes.map(rome => rome.substring(0, 1)))]
                            ?.map(largeProLetter => allLargeProfessionalFields?.find(largeProField => largeProField.code === largeProLetter)),
                        ...video
                    }
                }));
                setIsFetching(false);
            });
    }

    useEffect(() => {
        admin360Client?.uniqueId && isAdminView &&
            setSelectedClient([admin360Client?.uniqueId]);
    }, [admin360Client])

    useEffect(() => {
        selectedClient.length === 1 &&
            fetchWebAppAccess(selectedClient[0]);
    }, [selectedClient])

    useEffect(() => {
        webAppAccessCode &&
            fetchVideos(webAppAccessCode);
    }, [webAppAccessCode])

    const isEntitySelected = selectedEntity1.length > 0 || selectedEntity2.length > 0 || selectedEntity3.length > 0;

    const videoEntities = (video) => {
        return entityToAnalyse === "areasOfInterest"
            ? video.areasOfInterest
            : video.largeProfessionalFields;
    }

    const videosMatching3Entities = (entityCode1, entityCode2, entityCode3) => {
        return videos?.filter(video =>
            (!entityCode1 || videoEntities(video)?.some(entity => entity.code === entityCode1))
            && (!entityCode2 || videoEntities(video)?.some(entity => entity.code === entityCode2))
            && (!entityCode3 || videoEntities(video)?.some(entity => entity.code === entityCode3))
        )
    }

    const videosMatchingEntitiesSelected = useMemo(() => {
        return isEntitySelected
            ? videosMatching3Entities(selectedEntity1.length === 1 ? selectedEntity1[0] : undefined,
                selectedEntity2.length === 1 ? selectedEntity2[0] : undefined,
                selectedEntity3.length === 1 ? selectedEntity3[0] : undefined
            )
            : []
    }, [selectedEntity1, selectedEntity2, selectedEntity3])

    const videosWithoutEntity = useMemo(() => {
        return videos?.filter(video => videoEntities(video)?.length === 0);
    }, [videos, maxVideosInCommon, entityToAnalyse])

    const entitiesWithoutVideos = useMemo(() => {
        return allEntities.length && videos?.length
            ? allEntities?.filter(entity => !videos?.some(video => videoEntities(video)?.some(videoEntity => videoEntity.code === entity.code)))
            : [];
    }, [allEntities, videos, maxVideosInCommon, entityToAnalyse])

    const entitiesWithVideos = useMemo(() => {
        return entitiesWithoutVideos
            ? allEntities?.filter(entity => !entitiesWithoutVideos.some(entityWithoutVideo => entity.code === entityWithoutVideo.code))
            : [];
    }, [entitiesWithoutVideos, maxVideosInCommon, entityToAnalyse])

    const resetViewStates = () => {
        setCombinationsToDisplay([]);
        setFilteredCombinations([]);
        setPossibleRoutesToDisplay([]);
        setFilteredPossibleRoutes([]);
        setFilterValue("");
        setKey('combinations');
        setSelectedEntity1([]);
        setSelectedEntity2([]);
        setSelectedEntity3([]);
        setSortedCombinations([]);
        setSortedPossibleRoutes([]);
    }

    const toggleView = (isToggleAdminView) => {
        setSelectedClient(isToggleAdminView
            ? [admin360Client?.uniqueId]
            : [])
        setIsAdminView(isToggleAdminView);
        resetViewStates();
    }

    const generateCombinations = (entities, size) => {
        let result = [];
        function generateCombination(current, start) {
            if (current.length === size) {
                result.push([...current]);
                return;
            }
            for (let i = start; i < entities.length; i++) {
                current.push(entities[i]);
                generateCombination(current, i + 1);
                current.pop();
            }
        }
        generateCombination([], 0);
        return result;
    };

    const combinationsOf1 = useMemo(() => {
        return entitiesWithVideos.map(el => ({
            uniqueId: el.code,
            entity1: el,
            entity2: undefined,
            entity3: undefined,
            matchingVideos: videosMatching3Entities(el.code, undefined, undefined)
        }));
    }, [entitiesWithVideos, videos, maxVideosInCommon]);

    const combinationsOf2 = useMemo(() => {
        return generateCombinations(entitiesWithVideos, 2).map(comb => ({
            uniqueId: `${comb[0].code}-${comb[1].code}`,
            entity1: comb[0],
            entity2: comb[1],
            entity3: undefined,
            matchingVideos: videosMatching3Entities(comb[0].code, comb[1].code, undefined)
        }));
    }, [entitiesWithVideos, videos, maxVideosInCommon]);

    const combinationsOf3 = useMemo(() => {
        return generateCombinations(entitiesWithVideos, 3).map(comb => ({
            uniqueId: `${comb[0].code}-${comb[1].code}-${comb[2].code}`,
            entity1: comb[0],
            entity2: comb[1],
            entity3: comb[2],
            matchingVideos: videosMatching3Entities(comb[0].code, comb[1].code, comb[2].code)
        }));
    }, [entitiesWithVideos, videos, maxVideosInCommon]);

    const allCombinations = useMemo(() =>
        [...combinationsOf1, ...combinationsOf2, ...combinationsOf3]
        , [combinationsOf1, combinationsOf2, combinationsOf3]);

    const sortTypeCombinations = [
        {
            value: 'combination', label: 'Choix de ' + entityName, test: true,
            method: (a, b) => Number(a.entity1?.code) - Number(b.entity1?.code),
            display: (combination) => (combination.entity1 ? combination.entity1?.code + " - " + combination.entity1?.label : "")
                + (combination.entity2 ? ", " + combination.entity2?.code + " - " + combination.entity2?.label : "")
                + (combination.entity3 ? ", " + combination.entity3?.code + " - " + combination.entity3?.label : "")
        },
        {
            value: 'videos', label: 'XP en commun', test: true,
            method: (a, b) => a.matchingVideos?.length - b.matchingVideos?.length,
            display: (combination) => combination.matchingVideos?.length
        }
    ];

    const sortTypePossibleRoutes = [
        {
            value: 'video', label: 'Expérience immersive', test: true,
            display: (possibleRoute) => <MiniCardMediaComponent
                media={possibleRoute.video}
                mediaType="video"
                isLink={true}
                readOneSlug={readOneVideo.replace(':uniqueId', possibleRoute.uniqueId)}
                onlySummaryInfos={true} />,
            flatDisplay: (possibleRoute) => possibleRoute.video?.videoName
        },
        {
            value: 'possibleRoutes', label: 'Choix possibles',
            test: HelperMetiers360.isArrayContainsValue(possibleRoutesToDisplay, "routes"),
            method: (a, b) => {
                const aHasNoRoute = a.routes?.length === 0;
                const bHasNoRoute = b.routes?.length === 0;
                const aHasRouteTooMuchVideos = a.routes?.length > 0 && a.routes?.every(r => r.matchingVideosInCommon?.length > maxVideosInCommon);
                const bHasRouteTooMuchVideos = b.routes?.length > 0 && b.routes?.every(r => r.matchingVideosInCommon?.length > maxVideosInCommon);

                if (aHasNoRoute && bHasRouteTooMuchVideos
                    || (aHasNoRoute || aHasRouteTooMuchVideos) && !bHasNoRoute && !bHasRouteTooMuchVideos
                ) {
                    return 1;
                } else if (aHasRouteTooMuchVideos && bHasNoRoute
                    || (bHasNoRoute || bHasRouteTooMuchVideos) && !aHasNoRoute && !aHasRouteTooMuchVideos
                ) {
                    return -1;
                } else {
                    return 0;
                }
            },
            display: (possibleRoute) => possibleRoute?.routes?.length > 0
                ? <ul className="text-start">
                    {possibleRoute?.routes?.map(route =>
                        <li key={route?.combination}>{route?.combination}&nbsp;
                            <u className="clickable" onClick={() => openVideosMatchingModal(route?.uniqueId)}>
                                (XP en commun : {route?.matchingVideosInCommon?.length})
                            </u>
                        </li>)}
                </ul>
                : <>-</>,
            flatDisplay: (possibleRoute) => possibleRoute?.routes?.length > 0
                ? possibleRoute?.routes?.map(route => `${route?.combination} (XP en commun : ${route?.matchingVideosInCommon?.length})`)?.join(", ")
                : "-"
        }
    ];

    const openVideosMatchingModal = (uniqueId) => {
        const combination = allCombinations.find(comb => comb.uniqueId == uniqueId);

        setModalData({
            ...modalData,
            header: <>Expériences immersives communes</>,
            size: 'lg',
            content: combination?.matchingVideos?.length > 0
                ? <CardGroupMediaComponent
                    mediaType="video"
                    mediaList={combination?.matchingVideos}
                    readOne={readOneVideo}
                    isLink={true}
                    onlySummaryInfos={true} />
                : <Alert variant="warning">Aucune expérience immersive en commun</Alert>
        });
        setModalShow(true);
    }

    const combinationsWith0Video = useMemo(() =>
        allCombinations.filter(comb => comb.matchingVideos?.length === 0)
        , [allCombinations, maxVideosInCommon]);

    const combinationsWithTooMuchVideos = useMemo(() =>
        allCombinations.filter(comb => comb.matchingVideos?.length > maxVideosInCommon)
        , [allCombinations, maxVideosInCommon]);

    const filterCombinations = (filterValueSelected) => {
        setFilterValue(filterValueSelected);
        switch (filterValueSelected) {
            case "combinationsOf1":
                setFilteredCombinations(combinationsOf1);
                break;
            case "combinationsOf2":
                setFilteredCombinations(combinationsOf2);
                break;
            case "combinationsOf3":
                setFilteredCombinations(combinationsOf3);
                break;
            default:
                setFilteredCombinations(allCombinations);
                break;
        }
    }

    useEffect(() => {
        if (allCombinations?.length > 0 && possibleRoutesToDisplay?.length === 0) {
            const possibleRoutes = videos.map(video => {
                return {
                    uniqueId: video.uniqueId,
                    video: video,
                    routes: allCombinations
                        ?.filter(comb => comb.matchingVideos.some(v => v.uniqueId === video.uniqueId))
                        ?.map(comb => {
                            return {
                                uniqueId: comb.uniqueId,
                                combination: (comb?.entity1 ? `${comb?.entity1?.code} - ${comb?.entity1?.label}` : '')
                                    + (comb?.entity2 ? ` + ${comb?.entity2?.code} - ${comb?.entity2?.label}` : '')
                                    + (comb?.entity3 ? ` + ${comb?.entity3?.code} - ${comb?.entity3?.label}` : ''),
                                matchingVideosInCommon: comb.matchingVideos
                            }
                        })
                }
            });
            setPossibleRoutesToDisplay(possibleRoutes);
            setFilteredPossibleRoutes(possibleRoutes)
        }
    }, [videos, allCombinations])

    const videosWithoutPossibleRoutes = useMemo(() =>
        possibleRoutesToDisplay?.filter(possibleRoute => possibleRoute.routes?.length === 0)
        , [possibleRoutesToDisplay, maxVideosInCommon]);

    const videosWithOnlyPossibleRoutesWithTooMuchVideosInCommon = useMemo(() =>
        possibleRoutesToDisplay?.filter(possibleRoute => possibleRoute.routes?.length > 0
            && possibleRoute.routes?.every(r => r.matchingVideosInCommon?.length > maxVideosInCommon))
        , [possibleRoutesToDisplay, maxVideosInCommon]);

    return isFetchingAllClients
        ? <Loader />
        : <>
            <h2 className="d-flex justify-content-between">
                <div>
                    Analyse des choix de&nbsp;
                    <select multiple={false} onChange={(e) => {
                        setEntityToAnalyse(e?.target?.value);
                        resetViewStates();
                    }}>
                        <option value='areasOfInterest'>centres d'intérêt</option>
                        <option value='largeProfessionalFields'>domaines professionnels</option>
                    </select>
                </div>
                <ToggleButtonGroup type="radio" name="admin_client_view" onChange={toggleView} value={isAdminView} className='ms-2'>
                    <ToggleButton value={true} checked={isAdminView} key="admin_view" id="admin_view">Vue admin</ToggleButton>
                    <ToggleButton value={false} checked={!isAdminView} key="client_view" id="client_view">Vue client</ToggleButton>
                </ToggleButtonGroup>
            </h2>
            {isFetching
                ? <Loader />
                : <>
                    {!isAdminView && <Row>
                        <Form.Label>Sélectionnez un client :</Form.Label>
                        <SelectSingleClientComponent
                            selectId='clientSelect'
                            selectedClients={selectedClient}
                            setSelectedClients={(e) => {
                                setSelectedClient(e);
                                resetViewStates();
                            }}
                            initialClients={allClients} />
                    </Row>}
                    {selectedClient.length > 0 && <>
                        <Row className="mx-3">
                            <Col>
                                <ul className="mt-3">
                                    <li>Le client a accès à <b>{videos?.length}</b> expériences immersives via le code webApp : <b>{webAppAccessCode}</b></li>
                                    <li>
                                        {videosWithoutEntity.length > 0
                                            ? <>
                                                <b>{videosWithoutEntity.length} / {videos?.length} XP reliées à AUCUN {entityNameSingular} : </b>
                                                {videosWithoutEntity.map(video => video.videoName).join(", ")}
                                            </>
                                            : <b>Toutes les XP sont reliées à au moins un {entityNameSingular}</b>
                                        }
                                    </li>
                                    {isFetchingAllAreasOfInterest || isFetchingAllLargeProfessionalFields
                                        ? <Loader />
                                        : allEntities.length
                                            ? <li>
                                                {entitiesWithoutVideos.length > 0
                                                    ? <>
                                                        <b>{entitiesWithoutVideos.length} / {allEntities.length} {entityName} présents dans AUCUNE XP accessibles : </b>
                                                        {entitiesWithoutVideos.map(entity => entity.label).join(", ")}
                                                    </>
                                                    : <b>Tous les {entityName} sont présents dans les XP accessibles</b>
                                                }
                                            </li>
                                            : <Alert variant="danger">Récupération des {entityName} en echec</Alert>
                                    }
                                </ul>
                            </Col>
                            <Col className="d-flex justify-content-center align-items-center m-4">
                                <Form.Label className="fw-bold mb-0 w-auto">Nombre maximal d'XP autorisées en commun</Form.Label>
                                <Form.Control name="max-videos-in-common" type="number" style={{ width: "6em" }}
                                    min={maxVideosInCommon} defaultValue={maxVideosInCommon}
                                    onChange={(e) => setMaxVideosInCommon(Number(e?.target?.value))} />
                            </Col>
                        </Row>
                        <Tabs defaultActiveKey="manualSearch" id="tabs-entity-choices" activeKey={key} onSelect={(k) => setKey(k)}>
                            <Tab eventKey="combinations" title="Combinaisons de choix possibles">
                                <Row className="mx-3">
                                    <ul>
                                        <li>
                                            <b>{allCombinations?.length} combinaisons</b> de choix possibles parmis les <b>{entitiesWithVideos?.length} {entityName}</b> reliés à au moins une XP&nbsp;
                                            ({combinationsOf1?.length} combinaisons à 1 choix, {combinationsOf2?.length} combinaisons à 2 choix et {combinationsOf3?.length} combinaisons à 3 choix)
                                        </li>
                                        <li>
                                            <span className="background-red p-1">
                                                <b>{combinationsWith0Video?.length}</b>/{allCombinations?.length} choix menent à <b>0 XP commune</b>
                                            </span>
                                            ({combinationsOf1?.filter(c => c.matchingVideos?.length === 0)?.length}/{combinationsOf1?.length} combinaisons à 1 choix
                                            , {combinationsOf2?.filter(c => c.matchingVideos?.length === 0)?.length}/{combinationsOf2?.length} combinaisons à 2 choix
                                            et {combinationsOf3?.filter(c => c.matchingVideos?.length === 0)?.length}/{combinationsOf3?.length} combinaisons à 3 choix)
                                        </li>
                                        <li>
                                            <b>{allCombinations?.length - combinationsWith0Video?.length - combinationsWithTooMuchVideos?.length}</b>/{allCombinations?.length} choix menent à entre <b>1 et {maxVideosInCommon} XP communes </b>
                                            ({combinationsOf1?.filter(c => c.matchingVideos?.length > 0 && c.matchingVideos?.length <= maxVideosInCommon)?.length}/{combinationsOf1?.length} combinaisons à 1 choix
                                            , {combinationsOf2?.filter(c => c.matchingVideos?.length > 0 && c.matchingVideos?.length <= maxVideosInCommon)?.length}/{combinationsOf2?.length} combinaisons à 2 choix
                                            et {combinationsOf3?.filter(c => c.matchingVideos?.length > 0 && c.matchingVideos?.length <= maxVideosInCommon)?.length}/{combinationsOf3?.length} combinaisons à 3 choix)
                                        </li>
                                        <li>
                                            <span className="background-orange p-1">
                                                <b>{combinationsWithTooMuchVideos?.length}</b>/{allCombinations?.length} choix menent à <b>{`> ${maxVideosInCommon} XP communes`}</b>
                                            </span>
                                            ({combinationsOf1?.filter(c => c.matchingVideos?.length > maxVideosInCommon)?.length}/{combinationsOf1?.length} combinaisons à 1 choix
                                            , {combinationsOf2?.filter(c => c.matchingVideos?.length > maxVideosInCommon)?.length}/{combinationsOf2?.length} combinaisons à 2 choix
                                            et {combinationsOf3?.filter(c => c.matchingVideos?.length > maxVideosInCommon)?.length}/{combinationsOf3?.length} combinaisons à 3 choix)
                                        </li>
                                    </ul>
                                </Row>
                                <Row className="d-flex justify-content-between m-4">
                                    <ToggleButtonGroup type="radio" name="combinations_choice" onChange={filterCombinations} value={filterValue} className='ms-2'>
                                        <ToggleButton value="all" checked={filterValue === "all"} key="all" id="all">Tous</ToggleButton>
                                        <ToggleButton value="combinationsOf1" checked={filterValue === "combinationsOf1"} key="combinationsOf1" id="combinationsOf1">Combinaisons de 1 choix</ToggleButton>
                                        <ToggleButton value="combinationsOf2" checked={filterValue === "combinationsOf2"} key="combinationsOf2" id="combinationsOf2">Combinaisons de 2 choix</ToggleButton>
                                        <ToggleButton value="combinationsOf3" checked={filterValue === "combinationsOf3"} key="combinationsOf3" id="combinationsOf3">Combinaisons de 3 choix</ToggleButton>
                                    </ToggleButtonGroup>
                                </Row>
                                {filteredCombinations?.length > 0 && <Row>
                                    <DynamicTableInfinite
                                        contentTable={combinationsToDisplay.map(combination => {
                                            const matchingVideosCount = combination.matchingVideos?.length;
                                            return {
                                                ...combination,
                                                background: matchingVideosCount === 0
                                                    ? '#FFA7A9'
                                                    : matchingVideosCount > maxVideosInCommon
                                                        ? '#fac974'
                                                        : null
                                            }
                                        })}
                                        contentSort={sortTypeCombinations}
                                        index="uniqueId"
                                        valueInitSort="videos"
                                        handleClick={openVideosMatchingModal}
                                        downloadable={true}
                                        filename="combinaisons_choix_possibles"
                                        fetchData={fetchDataCombinations}
                                        hasMore={hasMoreCombinations}
                                        setSortedContent={setSortedCombinations}
                                        sortedContent={sortedCombinations}
                                        sortState={[shouldSortCombinations, setShouldSortCombinations]}
                                    />
                                </Row>}
                            </Tab>
                            <Tab eventKey="manualSearch" title="Recherche manuelle">
                                <Row className="m-3">
                                    <Form.Label><b>Rechercher les XP en commun aux {entityName} suivant:</b></Form.Label>
                                    <Col>
                                        <CustomSelectSingleComponent
                                            selectId="entity1"
                                            options={entitiesWithVideos}
                                            nameSelect="entity1"
                                            selectedOptions={selectedEntity1}
                                            setSelectedOptions={setSelectedEntity1}
                                            optionLabel="label"
                                            valueLabel="code"
                                            optionIndex="code" />
                                    </Col>
                                    <Col>
                                        <CustomSelectSingleComponent
                                            selectId="entity2"
                                            options={entitiesWithVideos}
                                            nameSelect="entity2"
                                            selectedOptions={selectedEntity2}
                                            setSelectedOptions={setSelectedEntity2}
                                            optionLabel="label"
                                            valueLabel="code"
                                            optionIndex="code" />
                                    </Col>
                                    <Col>
                                        <CustomSelectSingleComponent
                                            selectId="entity3"
                                            options={entitiesWithVideos}
                                            nameSelect="entity3"
                                            selectedOptions={selectedEntity3}
                                            setSelectedOptions={setSelectedEntity3}
                                            optionLabel="label"
                                            valueLabel="code"
                                            optionIndex="code" />
                                    </Col>
                                </Row>
                                {isEntitySelected && <Row className="mx-3">
                                    {videosMatchingEntitiesSelected.length > 0
                                        ? <>
                                            <b>{videosMatchingEntitiesSelected.length} expériences immersives en commun :</b>
                                            <CardGroupMediaComponent mediaType="video" mediaList={videosMatchingEntitiesSelected} readOne={readOneVideo} isLink={true} onlySummaryInfos={true} />
                                        </>
                                        : <Alert variant="warning">Aucune expérience immersive en commun</Alert>
                                    }</Row>
                                }
                            </Tab>
                            <Tab eventKey="possibleRoutes" title="Choix possibles pour trouver une XP">
                                <Row className="mx-3">
                                    <ul>
                                        <li>
                                            <span className="background-red p-1">
                                                <b>{videosWithoutPossibleRoutes?.length} XP </b>/ {videos?.length} ne sont atteignables par aucun choix
                                            </span>
                                        </li>
                                        <li>
                                            <b>{videos?.length - videosWithoutPossibleRoutes?.length - videosWithOnlyPossibleRoutesWithTooMuchVideosInCommon?.length} XP </b>/ {videos?.length} sont atteignables par des choix menant à entre 1 et {maxVideosInCommon} XP en commun
                                        </li>
                                        <li>
                                            <span className="background-orange p-1">
                                                <b>{videosWithOnlyPossibleRoutesWithTooMuchVideosInCommon?.length} XP </b>/ {videos?.length} sont atteignables que par des choix menant à plus de {maxVideosInCommon} XP en commun
                                            </span>
                                        </li>
                                    </ul>
                                </Row>
                                <DynamicTableInfinite
                                    contentTable={possibleRoutesToDisplay
                                        .map(possibleRoute => {
                                            const possibleRoutesCount = possibleRoute.routes?.length;
                                            return {
                                                ...possibleRoute,
                                                background: possibleRoutesCount === 0
                                                    ? '#FFA7A9'
                                                    : possibleRoute.routes?.every(r => r.matchingVideosInCommon?.length > maxVideosInCommon)
                                                        ? '#fac974'
                                                        : null
                                            }
                                        })}
                                    contentSort={sortTypePossibleRoutes}
                                    index="uniqueId"
                                    valueInitSort="possibleRoutes"
                                    downloadable={true}
                                    filename="choix_possibles"
                                    fetchData={fetchDataPossibleRoutes}
                                    hasMore={hasMorePossibleRoutes}
                                    setSortedContent={setSortedPossibleRoutes}
                                    sortedContent={sortedPossibleRoutes}
                                    sortState={[shouldSortPossibleRoutes, setShouldSortPossibleRoutes]}
                                />
                            </Tab>
                        </Tabs>
                    </>}
                </>}
            {modalComponent}
        </>;
}

export default ChoicesTestPage;