import React, { useEffect } from "react";
import { Container, Row, Col, Alert, Button } from "react-bootstrap";
import './HomeRomesPage.scss';
import { useStoreActions, useStoreState } from "easy-peasy";
import { useNavigate } from "react-router-dom";
import CountUp from 'react-countup';
import Loader from "../../components/widgets/Loader";

const HomeRomesPage = () => {
    const { allEntitiesRomeCount, isFetchingAllEntitiesRomeCount, homeRomesAlreadySeen } = useStoreState(state => state.romes);
    const { fetchAllEntitiesRomeCount, setHomeRomesAlreadySeen } = useStoreActions(actions => actions.romes);

    const readAllRomesSlug = useStoreState(state => state.actionSlugs.actionSlugsDispatcher('romes'))?.readAll;
    const readAllWorkingConditionsSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher('workingConditions'))?.readAll;
    const readAllAreasOfInterestSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher('areasOfInterest'))?.readAll;
    const readAllSkillsSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher('skills'))?.readAll;
    const readAllJobAppellationsSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher('jobAppellations'))?.readAll;
    const readAllProfessionalsSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher('professionals'))?.readAll;
    const readAllLargeProfessionalFieldsSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher('largeProfessionalFields'))?.readAll;

    const navigate = useNavigate();

    useEffect(() => {
        if ((allEntitiesRomeCount?.romesCount === undefined || allEntitiesRomeCount?.workingConditionsCount === undefined
            || allEntitiesRomeCount?.areasOfInterestCount === undefined || allEntitiesRomeCount?.skillsCount === undefined
            || allEntitiesRomeCount?.professionalsCount === undefined || allEntitiesRomeCount?.jobsAppellationsCount === undefined
            || allEntitiesRomeCount.largeProfessionalFieldsCount === undefined)
            && !isFetchingAllEntitiesRomeCount) {
            fetchAllEntitiesRomeCount();
        } else {
            setHomeRomesAlreadySeen(true);
        }
    }, [])

    return <Container fluid>
        <Row className="item-page">
            <Col>
                <h2>Exploration métiers</h2>
                <Alert variant="info" id="info-romes">
                    Construisez vos ateliers et interventions grâce à cette boîte à outils. Vous pourrez découvrir toutes les expériences immersives et
                    portraits de professionnel.les qui composent notre catalogue selon différents critères. Nous nous appuyons sur
                    le <a href="https://www.francetravail.org/opendata/repertoire-operationnel-des-meti.html?type=article" target="_blank">référentiel ROME 4.0</a>,
                    ce qui vous permet une exploration détaillée de notre catalogue, enrichie par le regard de celles et ceux qui exercent les différents
                    métiers :
                    <br />
                    <br />
                    Retrouvez nos <b>{allEntitiesRomeCount?.professionalsCount} professionnel⋅les</b> exerçant <b>{allEntitiesRomeCount?.jobsAppellationsCount} métiers</b>,
                    couvrant <b>{allEntitiesRomeCount?.romesCount} codes ROME</b> dans les <b>{allEntitiesRomeCount?.largeProfessionalFieldsCount} domaines professionnels</b>.
                    Ils permettent d'illustrer <b>{allEntitiesRomeCount?.workingConditionsCount} contextes de travail</b>, <b>{allEntitiesRomeCount?.areasOfInterestCount} centres d'intérêt</b>,
                    et <b>{allEntitiesRomeCount?.skillsCount} savoir-être professionnels</b>.
                </Alert>
            </Col>
        </Row>
        <Row className="d-flex">
            {!!readAllProfessionalsSlugs && <Col className="d-flex justify-content-center">
                <div className="card-entity card-entity-main"
                    onClick={() => navigate(readAllProfessionalsSlugs)}>
                    <div className="card-entity-content">
                        <div className="card-entity-number">{
                            isFetchingAllEntitiesRomeCount ?
                                <Loader />
                                : homeRomesAlreadySeen ?
                                    allEntitiesRomeCount?.professionalsCount
                                    : <CountUp end={allEntitiesRomeCount?.professionalsCount} />}
                        </div>
                        <span className="card-entity-title">Professionnel⋅les</span>
                    </div>
                    <div className="card-entity-content-hover">
                        <p>
                            Accédez à la liste des professionnel·les présent·es dans nos expériences immersives et à ce qu’ils et elles expriment au travers
                            de leurs témoignages. Ces informations sont catégorisées selon la classification ROME 4.0 (métiers, centres d’intérêts, savoir-être
                            et contextes de travail). 
                        </p>
                        <Button variant="primary-reverse">Explorer</Button>
                    </div>
                </div>
            </Col>}
            {!!readAllJobAppellationsSlugs && <Col className="d-flex justify-content-center">
                <div className="card-entity card-entity-main"
                    onClick={() => navigate(readAllJobAppellationsSlugs)}>
                    <div className="card-entity-content">
                        <div className="card-entity-number">{
                            isFetchingAllEntitiesRomeCount ?
                                <Loader />
                                : homeRomesAlreadySeen ?
                                    allEntitiesRomeCount?.jobsAppellationsCount
                                    : <CountUp end={allEntitiesRomeCount?.jobsAppellationsCount} />}
                        </div>
                        <span className="card-entity-title">Métiers</span>
                    </div>
                    <div className="card-entity-content-hover">
                        <p>
                            Découvrez les métiers issus du référentiel ROME 4.0. Chaque métier présent dans notre catalogue est associé à au moins un code ROME,
                            un domaine professionnel, un·e professionnel·le et une expérience immersive.
                        </p>
                        <Button variant="primary-reverse">Explorer</Button>
                    </div>
                </div>
            </Col>
            }
            {!!readAllRomesSlug && <Col className="d-flex justify-content-center">
                <div className="card-entity card-entity-main"
                    onClick={() => navigate(readAllRomesSlug)}>
                    <div className="card-entity-content">
                        <div className="card-entity-number">{
                            isFetchingAllEntitiesRomeCount ?
                                <Loader />
                                : homeRomesAlreadySeen ?
                                    allEntitiesRomeCount?.romesCount
                                    : <CountUp end={allEntitiesRomeCount?.romesCount} />}
                        </div>
                        <span className="card-entity-title">Codes ROME</span>
                    </div>
                    <div className="card-entity-content-hover">
                        <p>
                            Retrouvez la liste de tous les codes ROME existants ainsi que ceux associés à nos ressources. En cliquant
                            sur un code, accédez à une page synthèse où sont mentionnés les caractéristiques des métiers associés à ce code ROME, ainsi que toutes les
                            ressources M360 associées.
                        </p>
                        <Button variant="primary-reverse">Explorer</Button>
                    </div>
                </div>
            </Col>}
            {!!readAllLargeProfessionalFieldsSlugs && <Col className="d-flex justify-content-center">
                <div className="card-entity card-entity-main"
                    onClick={() => navigate(readAllLargeProfessionalFieldsSlugs)}
                >
                    <div className="card-entity-content" style={{ marginTop: "2em" }}>
                        <div className="card-entity-number">{
                            isFetchingAllEntitiesRomeCount ?
                                <Loader />
                                : homeRomesAlreadySeen ?
                                    allEntitiesRomeCount?.largeProfessionalFieldsCount
                                    : <CountUp end={allEntitiesRomeCount?.largeProfessionalFieldsCount} />}
                        </div>
                        <span className="card-entity-title">Domaines professionnels</span>
                    </div>
                    <div className="card-entity-content-hover" style={{ marginTop: "-15em" }}>
                        <p>
                            Accédez à la liste des différents domaines professionnels du référentiel ROME 4.0 et découvrez le nombre d’expériences immersives
                            présentes dans notre catalogue qui y sont associées.
                        </p>
                        <Button variant="primary-reverse">Explorer</Button>
                    </div>
                </div>
            </Col>}
            {!!readAllWorkingConditionsSlugs && <Col className="d-flex justify-content-center">
                <div className="card-entity card-entity-secondary"
                    onClick={() => navigate(readAllWorkingConditionsSlugs)}>
                    <div className="card-entity-content">
                        <div className="icon-entity">
                            <i className="fa fa-briefcase fa-lg" />
                        </div>
                        <div className="card-entity-number">{
                            isFetchingAllEntitiesRomeCount ?
                                <Loader />
                                : homeRomesAlreadySeen ?
                                    allEntitiesRomeCount?.workingConditionsCount
                                    : <CountUp end={allEntitiesRomeCount?.workingConditionsCount} />}
                        </div>
                        <span className="card-entity-title">Contextes de travail</span>
                    </div>
                    <div className="card-entity-content-hover">
                        <p>
                            Découvrez différents contextes de travail issus du ROME 4.0. Ils sont reliés aux professionnel·les et expériences immersives de
                            notre catalogue. Les contextes de travail sont classés en différentes catégories qui permettent
                            d’en apprendre plus sur les conditions d’exercice d’un métier.
                        </p>
                        <Button variant="primary-reverse">Explorer</Button>
                    </div>
                </div>
                </Col>
            }
            {!!readAllAreasOfInterestSlugs && <Col className="d-flex justify-content-center">
                <div className="card-entity card-entity-secondary"
                    onClick={() => navigate(readAllAreasOfInterestSlugs)}>
                    <div className="card-entity-content">
                        <div className="icon-entity">
                            <i className="fa fa-heart fa-lg" />
                        </div>
                        <div className="card-entity-number">{
                            isFetchingAllEntitiesRomeCount ?
                                <Loader />
                                : homeRomesAlreadySeen ?
                                    allEntitiesRomeCount?.areasOfInterestCount
                                    : <CountUp end={allEntitiesRomeCount?.areasOfInterestCount} />}
                        </div>
                        <span className="card-entity-title">Centres d'intérêt</span>
                    </div>
                    <div className="card-entity-content-hover">
                        <p>
                            Retrouvez les centres d’intérêt référencés sur ROME 4.0 et découvrez à quel·les professionnel·les et expériences immersives ils sont liés en fonction
                            des témoignages des personnes interviewées.
                        </p>
                        <Button variant="primary-reverse">Explorer</Button>
                    </div>
                </div>
                </Col>
            }
            {!!readAllSkillsSlugs && <Col className="d-flex justify-content-center">
                <div className="card-entity card-entity-secondary"
                    onClick={() => navigate(readAllSkillsSlugs)}>
                    <div className="card-entity-content" style={{ marginBottom: "-2em" }}>
                        <div className="icon-entity" style={{ marginTop: "0.5em", marginBottom: "2em" }}>
                            <i className="fa fa-user-cog fa-lg" />
                        </div>
                        <div className="card-entity-number" style={{ marginTop: "-1em" }}>{
                            isFetchingAllEntitiesRomeCount ?
                                <Loader />
                                : homeRomesAlreadySeen ?
                                    allEntitiesRomeCount?.skillsCount
                                    : <CountUp end={allEntitiesRomeCount?.skillsCount} />}
                        </div>
                        <span className="card-entity-title">Savoir-être professionnels</span>
                    </div>
                    <div className="card-entity-content-hover" style={{ marginBottom: "2em" }}>
                        <p>
                            Découvrez quels savoir-être sont nécessaires dans chaque métier en fonction de ce qu’expriment les professionnel·les figurant
                            dans de nos expériences immersives. Ces savoir-être sont tirés du référentiel ROME 4.0.
                        </p>
                        <Button variant="primary-reverse">Explorer</Button>
                    </div>
                </div>
                </Col>
            }
        </Row>
    </Container>;
}

export default HomeRomesPage;