import React from 'react';
import { useStoreState } from 'easy-peasy';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import HelperMetiers360 from '../../../../services/HelpersMetiers360.js';

const RomeSkillsTabComponent = (props) => {
    const { rome, skillsType, title } = props;
    const skills = rome[skillsType] ?? [];

    const skillsSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher("skills"));
    const OneSkillSlugs = skillsSlugs?.readOne ?? null;

    const skillsByType = [];
   
    skills.forEach((skill) => {
        const skillType = skill.typeCompetence;
        const skillTypeIndex = skillsByType.findIndex((skillTypeItem) => skillTypeItem.type === skillType);

        if (skillTypeIndex === -1) {
            skillsByType.push({
                type: skillType,
                skills: [skill]
            });
        } else {
            skillsByType[skillTypeIndex].skills.push(skill);
        }
    });

    const genericLabel = {
        'COMPETENCE-DETAILLEE':"Compétences détaillées",
        'MACRO-SAVOIR-FAIRE': "Savoir-faire",
        'SAVOIR':"Savoirs",
        'MACRO-SAVOIR-ETRE-PROFESSIONNEL': "Savoir-être professionnels"
    }

    return !!skillsByType.length && <>
        <h4>{title}</h4>
        {skillsByType.map((skillType) => {
            return <div key={HelperMetiers360.generateUniqueId()}>
                <h5 className='ms-4 mt-2 fw-bold'>
                    {skillType.type === "MACRO-SAVOIR-ETRE-PROFESSIONNEL" && <i className="fa fa-user-cog me-2" />}
                    {genericLabel[skillType.type] ?? skillType.type}
                </h5>
                <ul>
                    {skillType.skills.map((skill) =>
                        <li key={'skill_' + skill.code}>
                            {OneSkillSlugs && skillType.type == 'MACRO-SAVOIR-ETRE-PROFESSIONNEL'
                                ? <Link to={OneSkillSlugs.replace(':uniqueId', skill.code)} >{skill.label}</Link>
                                : <>{skill.label}</>
                            }
                        </li>)}
                </ul>
            </div>
        })}
    </>
}

RomeSkillsTabComponent.propTypes = {
    rome: PropTypes.object.isRequired,
    skillsType: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
};

export default RomeSkillsTabComponent