import { Alert } from 'react-bootstrap';
import HelperMetiers360 from "../services/HelpersMetiers360";
import iconClassNameRef from "./iconRefs";
import pagesComponents from "./pagesComponents";
import WelcomePage from "../pages/welcomePage/WelcomePage";
import ErrorPage from '../pages/errors/ErrorPage';
import AuthorizationChecker from '../services/AuthorizationChecker';
import ChoicesTestPage from '../pages/test/ChoicesTestPage';
import DesignSystemPage from '../pages/test/DesignSystemPage';
import GeneralTermsAndConditionsOfUse from '../pages/security/GeneralTermsAndConditionsOfUse';


const addingRoutesToPages = ( BASE_URL, {ACTIONS}, actionSlugs, pages) => {
    pages.push({
		id: HelperMetiers360.generateUniqueId(),
		menuItem: "Accueil",
		labelNav: 'Accueil',
		slug: BASE_URL,
		isExact: true,
		content: <WelcomePage />,
	})
	pages.push({
		id: 'error',
		menuItem: "error",
		labelBreadcrumb: 'Erreur',
		slug: BASE_URL + 'error' + "/:errorCode",
		isExact: true,
		content: <ErrorPage />,
	});
    pages.push({
        id: 'generalTermsAndConditionsOfUse',
        menuItem: "generalTermsAndConditionsOfUse",
        slug: BASE_URL + 'generalTermsAndConditionsOfUse',
        isExact: true,
        labelBreadcrumb: "Conditions générales d'utilisation",
        content: <GeneralTermsAndConditionsOfUse />,
    });

	if(AuthorizationChecker.isAdmin()) {
		pages.push({
            id: 'designSystem',
            menuItem: "designSystem",
            slug: BASE_URL + 'designSystem',
            isExact: true,
            content: <DesignSystemPage />
        })
        pages.push({
            id: 'test',
            menuItem: "test",
            slug: BASE_URL + 'test',
            isExact: true,
            content: <ChoicesTestPage />
        })
	}

	actionSlugs['home'] = BASE_URL;
		
	for (const entity in ACTIONS) {
		if(ACTIONS[entity].isEntity) {
			actionSlugs[entity] = {};
			if (ACTIONS[entity].readAll) {
				const slug = /*BASE_URL +*/ entity

				pages.push({
					id: `${entity}readAll`,
					'menuItem': entity,
					action: 'readAll',
					labelBreadcrumb: ACTIONS[entity].label,
					slug,
					isExact: true,
					content: pagesComponents[entity]['readAll'] ? pagesComponents[entity]['readAll'] : <p>{ACTIONS[entity].label}</p>,
				});
		
				actionSlugs[entity]['readAll'] = BASE_URL + slug;
		
			}
			if (ACTIONS[entity]["create"]) {
				let addParams = "";
				if(ACTIONS[entity]["create"]["withParams"]){
					addParams = ACTIONS[entity]["create"]["withParams"]
						.reduce((acc,param) => acc+'/'+param.key+'/:'+param.value,addParams);
				}
				const slug = /*BASE_URL +*/ entity + addParams + "/create";
				pages.push({
					id: `${entity}create`,
					'menuItem': entity,
					action: 'create',
					labelBreadcrumb: `création`,
					slug,
					isExact: true,
					content: pagesComponents[entity]['create'] ? pagesComponents[entity]['create'] :<p>{entity} upload</p>,
				});
		
				actionSlugs[entity]['create'] = BASE_URL + slug;
			}
			if (ACTIONS[entity]["readOne"]) {
				const slug = /*BASE_URL +*/  entity + "/:uniqueId";
		
				pages.push({
					id: `${entity}readOne`,
					'menuItem': entity,
					action: 'readOne',
					slug,
					isExact: true,
					content: pagesComponents[entity]['readOne'] ? pagesComponents[entity]['readOne'] :<p>{entity} unique</p>,
				});
		
				actionSlugs[entity]['readOne'] = BASE_URL + slug;
			}
			if (ACTIONS[entity]["getSubEntities"]) {
				for (const subentity in ACTIONS[entity]["getSubEntities"]) {
					if(ACTIONS[entity].getSubEntities[subentity].readOne) {
						const slug = /*BASE_URL +*/  entity + "/:uniqueId/" + subentity+ "/:otherId";
						pages.push({
							id: `${entity}${subentity}readOneWithSub`,
							slug,
							content: pagesComponents[subentity]['readOne'] ? pagesComponents[subentity]['readOne'] :<p>{subentity} unique</p>,
						});

						actionSlugs[entity]['readOneWithSub'] = BASE_URL + slug;
					}
					if(ACTIONS[entity].getSubEntities[subentity].update) {
						const slug = /*BASE_URL +*/  entity + "/:uniqueId/" + subentity+ "/:otherId";
						pages.push({
							id: `${entity}${subentity}updateOneWithSub`,
							slug,
							content: pagesComponents[entity]['update'] ? pagesComponents[entity]['update'] :<p>{entity} unique</p>,
						});

						actionSlugs[entity]['updateOneWithSub'] = BASE_URL + slug;
					}
				}				
			}
			if (ACTIONS[entity]["update"]) {
				const slug = /*BASE_URL +*/ entity + "/:uniqueId/edit";
		
				pages.push({
					id: `${entity}update`,
					'menuItem': entity,
					action: 'update',
					labelBreadcrumb: `mise à jour`,
					slug,
					isExact: true,
					content: pagesComponents[entity]['update'] ? pagesComponents[entity]['update']: <p>{entity} edit</p>,
				});
		
				actionSlugs[entity]['update'] = BASE_URL + slug;
		
			}
		}
	}
}

const addingMenuLinksToPages = (BASE_URL, {MENU, ACTIONS}, pages) => {
    let order = 0;
    
	for (const menuItem of MENU) {
		switch (menuItem.type) {
			case 'separator': {
				pages.push({
					id: HelperMetiers360.generateUniqueId(),
					labelNav: menuItem.label, 
					isNavLink: true, 
					isSeparator: true,
					order
				 });
				break;
			}
			case 'link':{
				pages.push({
					id: HelperMetiers360.generateUniqueId(),
					labelNav: menuItem.label, 
					icone: iconClassNameRef[menuItem.pageSlug] ?? null, 
					isNavLink: true, 
					isLink: true,
					url: menuItem.url,
					order	
				});
				break;
			}
			case 'page':{
				const slug = BASE_URL + menuItem.pageSlug
				pages.push({
					id: HelperMetiers360.generateUniqueId(),
					labelNav: menuItem.label, 
					slug,
					icone: iconClassNameRef[menuItem.pageSlug] ? iconClassNameRef[menuItem.pageSlug]: iconClassNameRef['default'],
					isNavLink: true, 
					content: pagesComponents[menuItem.pageSlug] && pagesComponents[menuItem.pageSlug]['index'] ? pagesComponents[menuItem.pageSlug]['index']: <Alert>Page {menuItem.label} à venir</Alert>,
					order,
                    activeRoutes: menuItem.activeRoutes
				});
				break;
			}
			case 'entity':{
				if (ACTIONS[menuItem.entity]["readAll"]) { 
					const entityIndex = pages.findIndex(page => page.id === menuItem.entity+'readAll');
					pages[entityIndex] = {...pages[entityIndex],
						labelNav: menuItem.label,
						icone: iconClassNameRef[menuItem.entity] ? iconClassNameRef[menuItem.entity]: iconClassNameRef['default'],
						isNavLink: true,
						order,
						activeRoutes: menuItem.activeRoutes
					}
				}
				break;
			}
			default:
				break;
		}
		order++;
	}
}

const computeRoutingData = (apiData) => {
	const BASE_URL = '/';
	const pages = [];
	const actionSlugs = {}

    addingRoutesToPages(BASE_URL, apiData, actionSlugs, pages);
    addingMenuLinksToPages(BASE_URL, apiData, pages)

	return {pages, actionSlugs};
}

export default computeRoutingData;