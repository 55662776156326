// import react
import React, { useEffect } from "react";
// import react router
import { Link, NavLink, useLocation } from "react-router-dom";
// import bootstrap
import {  Nav } from "react-bootstrap";
// import easy peasy
import {useStoreState, useStoreActions} from 'easy-peasy';

import useFreshworkWidget from "../../hooks/useFreshworksWidget";
import PropTypes from 'prop-types';

import logo from '../../images/logo140_squared.png';

//imports locaux

import "./SideMenu.scss";

const SideMenu = ({dynamicStyle}) => {
	const {openWidget: openFreshworksWidget} = useFreshworkWidget();

    const { isMobile, isTablet, navIsOpen } = useStoreState((state) => state.responsive);
    const { setIsMobile, setIsTablet, setNavIsOpen } = useStoreActions((state) => state.responsive);
	
	const {pages} = useStoreState(state=> state.actionSlugs);
	
    const { pathname } = useLocation();
	// const LOGO_URL = BACKOFFICE_DEFAULT_VALUES.logoUrl;

	const nav = (
		<>
            {pages?.length ?
				pages.filter((page) => page.isNavLink)
				.sort((a,b) => a.order - b.order)
				.map((page) => {
					if (page.isSeparator) {
						return (
							<li
								key={page.id}
								className='separator'
							>
								{ page.labelNav.toString().toUpperCase() }
							</li>
						);
					}
					else if (page.isLink) {
						return <Nav.Link href={page.url} target="_blank" key={page.id}>
                            <li className='nav-item'>
                                {page.icone ? (
                                        <i className={"nav-icon icon-" + page.icone}></i>
                                    ) : <i className="nav-icon fas fa-link" />}{" "}
                                { page.labelNav.toString() }
                            </li>
                        </Nav.Link>;
					}
					else {
						return (
							<NavLink
								className={({isActive}) => isActive 
										|| page.activeRoutes?.includes(pathname?.split('/')[1]) 
									? 'current-link' : undefined}
								// exact={page.isExact}
								to={page.slug}
								key={page.id}
								onClick={() => {setNavIsOpen(false)}}
							>
								<li className='nav-item'>
									{page.icone ? (
										<i className={"nav-icon icon-" + page.icone}></i>
									) : null}{" "}
									{page.labelNav}
								</li>
							</NavLink>
						);
					}
				})
				: []
				}
		</>
	);

    const handleResize = () => {
        if (window.innerWidth <= 768) {
			setIsMobile(true);
        } else if (window.innerWidth <= 1200) {
            setIsMobile(false);
            setIsTablet(true);
		} else {
			setIsMobile(false);
            setIsTablet(false);
		}
    };

	useEffect(() => {
        handleResize();

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
    }, [setIsMobile, setIsTablet]);

	return (
		<>
            {((!isMobile && !isTablet) || navIsOpen) && (
				<div id='sidemenu-wrapper' className='col-fixed px-0' style={dynamicStyle}>
						<div className='logo-container'>
						<NavLink className="mx-auto" end to={'/'}>
							<img
                                src={logo}
								id="logo"
								alt='metiers360'
							/>
						</NavLink>
					</div>
					<nav className='nav'>
						<ul className='nav-wrapper'>
							{nav}
							<li onClick={openFreshworksWidget} className='nav-item'>
								<i className="nav-icon icon-question"></i>&nbsp;Aide
							</li>
						</ul>
					</nav>
                    <nav className='nav'>
                        <Link to="/generalTermsAndConditionsOfUse" className="text-white mx-2 mb-4">
                            Conditions générales d'utilisation
                        </Link>
                    </nav>
                </div>
			)}
		</>
	);
};

SideMenu.propTypes = {
    dynamicStyle: PropTypes.object,
};

export default SideMenu;
