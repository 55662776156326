import React, { useEffect, useState } from "react";
import { Form, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import Loader from '../widgets/Loader.jsx';
import PropTypes from 'prop-types';
import { useStoreState } from "easy-peasy";
import axios from "../../services/axios.js";
import ChronogramComponent from "./ChronogramComponent.jsx";
import GeoMap from "./GeoMap.jsx";
import ClientCategories from "./ClientCategories.jsx";
import MeanViewsDuration from "./MeanViewsDuration.jsx";
import MeanViewsDurationPercent from "./MeanViewsDurationPercent.jsx";

const GraphVideosComponent = ({ graphType, videosUuid = [], minDate = undefined, maxDate = undefined,
    infoDisplayed = "video_views", aggregatable = false, filterableByDates = true, toggleData = null,
    height = "100%", width = "100%" }) => {

    const { apiData: { ACTIONS } } = useStoreState(state => state.actionSlugs);
    const [aggregate, setAggregate] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [graphData, setGraphData] = useState([]);
    const [selectedGraphType, setSelectedGraphType] = useState();
    const [graphTypeToDisplay, setGraphTypeToDisplay] = useState(graphType);
    const [mapTypeToDisplay, setMapTypeToDisplay] = useState("regions");

    useEffect(() => {
        toggleData && setSelectedGraphType(toggleData?.find(data => data.defaultChecked)?.value);
    }, [])

    const textOfInfoDisplayed = {
        video_views: "vues",
        unique_clients_total: "clients",
        unique_devices_total: "casques",
    }
    const graphTitleLookup = {
        chronogram: "Évolution dans le temps du nombre de vues hebdomadaires",
        geoMap: `Répartition géographique du nombre de ${textOfInfoDisplayed[infoDisplayed]}`,
        clientCategories: `Nombre de ${textOfInfoDisplayed[infoDisplayed]} par catégories d'organisations`,
        meanViewsDuration: "Durées de lecture par nombre de vues",
        meanViewsDurationPercent: "Durées de lecture par nombre de vues"
    };
    const graphLookup = {
        chronogram: <ChronogramComponent data={graphData} aggregated={aggregate} height={height} />,
        geoMap: <GeoMap data={graphData} infoDisplayed={infoDisplayed} height={height} mapType={mapTypeToDisplay} />,
        clientCategories: <ClientCategories data={graphData} infoDisplayed={infoDisplayed} width={width} height={height} />,
        meanViewsDuration: <MeanViewsDuration data={graphData} aggregated={aggregate} height={height} />,
        meanViewsDurationPercent: <MeanViewsDurationPercent data={graphData} aggregated={aggregate} height={height} />
    };

    const fetchDataForGraph = () => {
        if (videosUuid.length > 0) {
            const postUrl = ACTIONS?.producerStats?.getStatsForContents?.url;
            setIsFetching(true);

            axios.post(postUrl, {
                entity: 'videos',
                uuid: videosUuid,
                startDate: minDate,
                endDate: maxDate,
                statType: graphTypeToDisplay,
                aggregated: aggregate
            }, {
                responseType: "json",
            })
                .then((response) => {
                    setGraphData(response);
                })
                .finally(() => setIsFetching(false));
        }
    }

    useEffect(() => {
        fetchDataForGraph();
    }, [videosUuid, graphTypeToDisplay]);

    useEffect(() => {
        if (filterableByDates) {
            fetchDataForGraph();
        }
    }, [minDate, maxDate])

    useEffect(() => {
        if (aggregatable) {
            fetchDataForGraph();
        }
    }, [aggregate])

    const toggleChange = (newValue) => {
        setSelectedGraphType(newValue);
        const toggleDataSelected = toggleData.find(data => data.value === newValue);
        toggleDataSelected?.graphType && setGraphTypeToDisplay(toggleDataSelected?.graphType);
        toggleDataSelected?.mapType && setMapTypeToDisplay(toggleDataSelected?.mapType);
    }

    return videosUuid?.length > 0 && <>
        <div className="d-flex align-items-center justify-content-between">
            <h4 className={aggregatable ? "mb-1" : "mb-3"}>{graphTitleLookup[graphTypeToDisplay]}</h4>
            {toggleData &&
                <ToggleButtonGroup className='py-2' type="radio" name={"graph" + graphTypeToDisplay} id={"graph" + graphTypeToDisplay} value={selectedGraphType}
                    onChange={toggleChange}>
                    {toggleData.map(data =>
                        <ToggleButton value={data.value} key={data.value} id={data.value} checked={selectedGraphType == data.value} className='mb-0'>
                            {data.label}
                        </ToggleButton>
                    )}
                </ToggleButtonGroup>}
        </div>
        {aggregatable && videosUuid?.length > 1 &&
            <Form.Check id={"aggregate" + graphTypeToDisplay} className="clickable mb-3 ms-3" label="Agréger les données"
                defaultChecked={aggregate} onChange={() => setAggregate((prev) => !prev)} />}
        {isFetching
            ? <Loader />
            : graphLookup[graphTypeToDisplay]
        }
    </>;
}

GraphVideosComponent.propTypes = {
    graphType: PropTypes.string.isRequired,
    videosUuid: PropTypes.array.isRequired,
    infoDisplayed: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
    minDate: PropTypes.instanceOf(Date),
    maxDate: PropTypes.instanceOf(Date),
    aggregatable: PropTypes.bool,
    filterableByDates: PropTypes.bool,
    toggleData: PropTypes.array,
}

export default GraphVideosComponent;