import React, { useState, useEffect, useRef } from 'react';
import { Row } from 'react-bootstrap';
import {Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	PointElement,
	LineElement,
	Tooltip} from 'chart.js'; // 
import {Line, Bar} from 'react-chartjs-2';
import { useStoreState } from 'easy-peasy';

import axios from '../../services/axios';

import './VideoPlayGraph.scss';
import Loader from '../widgets/Loader';


const VideoPlayGraph = (props) => {
	const { clientsUniqueId = [], setIsFetching, isFetching, oneMonth, fromAdminWelcomePage = false, fromStatsPage = false } = props;

	const {apiData: {ACTIONS}} = useStoreState(state => state.actionSlugs);

    let color = 'rgb(207, 119, 255)';
	const [hasDataForVideosPlayed, setHasDataForVideosPlayed] = useState(false);
	const [hasDataForTopVideos, setHasDataForTopVideos] = useState(false);

	const [lineDisplay, setLineDisplay] = useState(null);
	const [barDisplay, setBarDisplay] = useState(null);

	const timeoutDelay = fromStatsPage ? 1000 : 0;

	const isFirstRender = useRef(true);

	ChartJS.register(
		CategoryScale,
		LinearScale,
		PointElement,
		LineElement,
		BarElement,
		Tooltip
	  );

	const fetchAndProcessData = async () => {

		let days = [];
		let data = [];
		let daysNumber = 30;
		const today = new Date();
		const date30DaysAgo = new Date(new Date().setDate(today.getDate() - daysNumber));

		if ((clientsUniqueId?.length > 0 || fromAdminWelcomePage) && !isFetching) {

			setIsFetching(true);

            const monthPlayRequest = axios.post(ACTIONS?.clients?.getProps?.getPlay?.url, { uuid: clientsUniqueId, oneMonth: oneMonth }, { responseType: "json" });
			const topVideosRequest = axios.post(ACTIONS?.clients?.getProps?.getTopPlayed?.url, { uuid: clientsUniqueId, oneMonth: oneMonth }, { responseType: "json" });

			Promise.all([monthPlayRequest, topVideosRequest])
				.then(([monthPlayResponse, topVideosResponse]) => {
					if (monthPlayResponse && monthPlayResponse?.length > 0) {
						setHasDataForVideosPlayed(true);

						let currentDay;
						let startDate;

						if (oneMonth) {
							currentDay = date30DaysAgo;
							startDate = date30DaysAgo;
						} else {
							const earliestData = monthPlayResponse.reduce((earliest, dayCount) => {
								const date = new Date(dayCount.year, dayCount.month - 1, dayCount.day);
								return date < earliest ? date : earliest;
							}, new Date());
							startDate = earliestData;
							currentDay = new Date(earliestData);

							const today = new Date();
							daysNumber = Math.ceil((today - startDate) / (1000 * 60 * 60 * 24));
						}

						let currentMonthName = currentDay.toLocaleString('default', { month: 'long' });

						for (let i = 1; i <= daysNumber; i++) {
							days.push(`${currentDay.getDate()} ${currentMonthName} ${!oneMonth ? currentDay.getFullYear() : ""}`);
							const currentDayData = monthPlayResponse.find(
								(dayCount) =>
									parseInt(dayCount.day) === currentDay.getDate() &&
									parseInt(dayCount.month) === currentDay.getMonth() + 1 &&
									parseInt(dayCount.year) === currentDay.getFullYear()
							);
							data.push(currentDayData ? currentDayData.views : 0);

							currentDay = new Date(currentDay.setDate(currentDay.getDate() + 1));
							currentMonthName = currentDay.toLocaleString('default', { month: 'long' });
						}

						const options = { responsive: true };
						const dataToDisplay = {
						labels: days,
						datasets: [{
                            label: 'Expériences immersives vues',
							backgroundColor: color,
							borderColor: color,
							data,
							cubicInterpolationMode: 'monotone',
						}],
					};
						setLineDisplay(<Line options={options} data={dataToDisplay} />);
					} else {
						setHasDataForVideosPlayed(false);
					}

					if (topVideosResponse && topVideosResponse?.length > 0) {
						setHasDataForTopVideos(true);
						const { topVideosViews, topVideosNames } = topVideosResponse.reduce(
							(acc, video) => {
								acc.topVideosViews.push(video.views);
								acc.topVideosNames.push(video.subHeading ? [video.videoName, video.subHeading] : video.videoName);
								return acc;
							},
							{ topVideosViews: [], topVideosNames: [] }
						);

						const options = {
							indexAxis: 'y',
							scales: {
								y: { beginAtZero: true },
							},
						};
						const dataToDisplay = {
							labels: topVideosNames,
							datasets: [{
								data: topVideosViews,
								backgroundColor: color,
								borderColor: color,
								label: 'Expériences immersives vues',
							}],
						};
						setBarDisplay(<Bar options={options} data={dataToDisplay} />);
					} else {
						setHasDataForTopVideos(false);
					}
				})
				.catch((error) => {
					console.error("Error fetching data:", error);
				})
				.finally(() => {
					setIsFetching(false);
				});
		} else {
			setLineDisplay(null);
			setBarDisplay(null);
		}

	}

	useEffect(() => {
		let timeoutId;

		if ((clientsUniqueId?.length > 0 || fromAdminWelcomePage) && !isFetching) {
			timeoutId = setTimeout(() => {
				fetchAndProcessData();
			}, timeoutDelay);

			return () => clearTimeout(timeoutId);
		}
	}, [clientsUniqueId]);


	useEffect(() => {

		if (isFirstRender.current) {
			isFirstRender.current = false;
			return;
		}

		if ((clientsUniqueId?.length > 0 || fromAdminWelcomePage) && !isFetching) {
			fetchAndProcessData();
		}
	}, [oneMonth]);


	const noData = () => {
		if ((!clientsUniqueId || clientsUniqueId?.length == 0) && !fromAdminWelcomePage) {
			return <p>Veuillez sélectionner au moins un établissement</p>
		} else {
			return <p>Pas de données à afficher</p>
		}
	}


    return (
		isFetching ?
			<Loader />
			: <>
				<div className="stat-graph">
					<h4>Nombre de lectures{!!oneMonth && " au cours des 30 derniers jours"}</h4>
					{hasDataForVideosPlayed ? lineDisplay : <Row className='no-data'>{noData()}</Row>}
				</div>
				<div className="stat-graph">
					<h4>Top 5 des expériences immersives vues{!!oneMonth && " au cours des 30 derniers jours"}</h4>
					{hasDataForTopVideos ? barDisplay : <Row className='no-data'>{noData()}</Row>}
				</div>
			</>
	);

}

export default VideoPlayGraph;