import React, { useState, useEffect, useRef } from "react";

import { useNavigate } from "react-router-dom";

import {Form, Alert, Image, Row, Col, Button} from 'react-bootstrap';

import Dropzone from 'dropzone';

import { useStoreState } from "easy-peasy";
import { Editor } from "@tinymce/tinymce-react";
import SelectMultipleClientsComponent from "../widgets/selectClient/SelectMultipleClientsComponent.jsx";
import ButtonLoaderComponent from "../widgets/ButtonLoaderComponent";
import {URLS} from '../../services/axios';
import useCustomGetStoreState from "../../hooks/useCustomGetStoreState";
import { useToast } from "../../hooks/useToast.jsx";

import "./UploadVideoComponent.scss";
import SelectMultipleItemsWithSearchBar from "../widgets/selectMultipleItemsWithSearchBar/SelectMultipleItemsWithSearchBar.jsx";

import HelperMetiers360 from "../../services/HelpersMetiers360.js";

import DatePicker, { registerLocale } from "react-datepicker";
import { fr } from 'date-fns/locale/fr';

import "react-datepicker/dist/react-datepicker.css";

registerLocale('fr', fr);


const dropzoneOptions = {
	url: URLS.API_URL + "/_uploader/gallery/upload",
	headers: {
		'Cache-Control': null,
		'X-Requested-With': null,

	},
	withCredentials: true,
	chunking: true,
	maxFilesize: 2000,
	chunkSize: 2000000,
	retryChunks: true,
	retryChunksLimit: 3,
	acceptedFiles: "video/*",
	maxFiles: 1,
	autoProcessQueue: false,
	addRemoveLinks: true,
	dictRemoveFile: "Retirer le fichier",
    dictDefaultMessage: "Charger une nouvelle expérience immersive",

	dictCancelUpload: "Annuler ",
	dictCancelUploadConfirmation:
		"Etes-vous sûr de vouloir annuler le chargement ? ",
};

/**
 * 
 * 	UploadVideoComponent
 *  component with a form to create a new video or update an existing one
 * @param {callback} postVideo callback function that will POST a new video
 * @param {callback} updateVideo callback function that will update (PUT) an existing video
 * @param {Object} videoToUpdate Optionnal - Object with the infos of the video in case of an update, or null/undefined
 * @param {String} readAll  readAll slug to access the video list page
 * @param {String} readOne  readOne slug to access a video details page - replace the :uniqueId part of string with actual uniqueId
 * @param {String} create  create slug to access the video creation page - optional, need according rights
 * @param {String} update  update slug to access the video update page - replace the :uniqueId part of string with actual uniqueId  - optionnal, need according rights
 * @param {Object} returnLink object with informations (link, label) for the return button
 * @example
 * 
 * <UploadVideoComponent postVideo={callback} updatevideo={callback} videoToUpdate={Object} returnLink={returnLink} readOne={readOneSlug} readAll={readAllSlug} {...}/> 
 */

const UploadVideoComponent = (props) => {

	let navigate = useNavigate();
	
	const { action, postVideo, updateVideo, videoToUpdate, readOne: readOneVideoSlug } = props;
	const {apiData: {ACTIONS, BACKOFFICE_DEFAULT_VALUES}} = useStoreState(state => state.actionSlugs);

	const {allGroups} = useCustomGetStoreState('groups');
    const { allRomes } = useCustomGetStoreState('romes');

	const { fileContraints: fileContraints } = BACKOFFICE_DEFAULT_VALUES;
	const { typeOfLanguage: allLanguages } = BACKOFFICE_DEFAULT_VALUES;

	const uploadFormRef = useRef(null);
	
	const [isSuccess, setIsSuccess] = useState(false);
	const [formErrors, setFormErrors] = useState({});

	const [releaseDate, setReleaseDate] = useState(videoToUpdate?.releaseDate ? new Date(videoToUpdate.releaseDate) : null);


	// form content state variable
	const [dropzone, setDropzone] = useState(null);
	const [isPrivateVideo, setIsPrivateVideo] = useState(videoToUpdate?.private ?? false)
	const [isAlwaysVisible, setIsAlwaysVisible] = useState(videoToUpdate?.isAlwaysVisible ?? false)
	const [isDirectlyAccessible, setIsDirectlyAccessible] =  useState(action === 'create' ? true : (videoToUpdate?.isDirectlyAccessible ? videoToUpdate.isDirectlyAccessible : false))

	const editorContentRef = useRef(videoToUpdate?.description ? videoToUpdate.description: '')
	const setEditorContent = data => {
		editorContentRef.current = data;
	}

	const [selectedRomes, setSelectedRomes] = useState(videoToUpdate?.romes ? videoToUpdate.romes.map(String) : []);
	const [selectedGroups, setSelectedGroups] = useState(videoToUpdate?.groups ? videoToUpdate.groups.map((group) => { return group.uniqueId }) : []);
	const [videoName, setVideoName] = useState(videoToUpdate?.videoName ?? '')
	const [subHeading, setSubHeading] = useState(videoToUpdate?.subHeading ?? '')
	// input names
	const [thumbnailFileInputName, setThumbnailFileInputName] = useState(videoToUpdate?.links?.thumbnail ? 'Changer la vignette' : 'Choisir le fichier');
	const [countSubtitles, setCountSubtitles] = useState(1);
	const [subtitleFileInputName, setSubtitleFileInputName] = useState([]);
	const [subLangSelected, setSubLangSelected] = useState([]);
	const [isSending, setIsSending] = useState(false);

	const token = ACTIONS['videos'][action].csrfToken;
	
	const [selectedClients, setSelectedClients] = useState(videoToUpdate?.producers?.map(producer => producer.id).map(String) ?? []);

	const toast = useToast();

	// init dropzone on component did mount
	useEffect(() => {

        const myDropzone = new Dropzone("#dropzone-content", dropzoneOptions); 
		setDropzone(myDropzone);

		Dropzone.autoDiscover = false;

		return () => {
			dropzone && dropzone.destroy();
		}
	}, []);

	// adding event Listeners on the dropzone
	useEffect(() => {
		// Prevent from having multiples files, it's not possible to have multiple file option with chunking option
		if (!dropzone){
			return ;
		}

		dropzone.on("maxfilesexceeded", function (file) {
			this.removeAllFiles();
			this.addFile(file);
		});

		// Remove file when upload finished, get name from serveur
		dropzone.on("complete", function (file) {
		    this.removeFile(file);

			let formData = new FormData (uploadFormRef.current);

		    let dataFromServer = JSON.parse(file.xhr.response);

		    formData.append("video_upload[fileSize]", file.size);
		    formData.append("video_upload[videoSource]", dataFromServer['fileName']);
			formData.append("video_upload[description]", editorContentRef.current);

			action === 'create'
				? postVideo({formData})
					.then((result) => {
						const { uniqueId } = result;
						setIsSuccess(true);
						navigate(
							readOneVideoSlug.replace(':uniqueId', uniqueId),
							{
								replace: true,
								state: {fromEdit: true}
							
							});
					})
					.catch((error) => {
						setIsSending(false);
						handleBadRequest(error);

					})
				: updateVideo({formData, uniqueId: videoToUpdate.uniqueId})
					.then(() => {
						setIsSuccess(true);
						navigate(readOneVideoSlug.replace(':uniqueId', videoToUpdate.uniqueId),
						{
							replace: true,
							state: {fromEdit: true}
						
						});
					})
					.catch((error) => {
						setIsSending(false);
						handleBadRequest(error);
					});
		
		});

	}, [dropzone]);

	const imageType = ['image/jpeg', 'image/jpg', 'image/png','image/webp'];

	const checkData = () => {
		setFormErrors({});
		setIsSuccess(false);

		let file = dropzone.files[0];
		let errorList = {};


		if (!file && (!videoToUpdate)) {
            errorList['media'] = 'Veuillez sélectionner une expérience immersive';
		} 

		
		uploadFormRef.current = document.getElementById('videoUploadData-form');
		let formData = new FormData (uploadFormRef.current);
		let thumbnail = formData.get('video_upload[thumbnailSource]');

		if (!formData.get('video_upload[videoName]')) {
            errorList['videoName'] = 'Veuillez saisir le nom de l\'expérience immersive';
        }
        if (formData.get('video_upload[videoName]')?.length > 50) {
            errorList['videoName'] = 'Le nom de l\'expérience immersive ne doit pas dépasser 50 caractères';
        }

		if ((!videoToUpdate && !thumbnail.size) || ( thumbnail.size && !imageType.includes(thumbnail.type))) {
            errorList['thumbnailSource'] = 'Veuillez sélectionner une vignette pour l\'expérience immersive';

        }

		if (thumbnail.size ) {
            if (thumbnail.size > fileContraints.MAX_SIZE_IMAGE_BYTES ) {
                errorList['thumbnailSource'] = "Vignette trop volumineuse";
            }
            if (!fileContraints.IMAGE_MIME_TYPES.includes(thumbnail.type) ) {
                errorList['thumbnailSource'] = "Format de la vignette non autorisé";
            }
        }

		if (!releaseDate) {
			errorList['releaseDate'] = 'Veuillez sélectionner une date de publication';

        }


		if (Object.keys(errorList).length) {
			setFormErrors(errorList); //object

			toast.open({ message: Object.values(errorList)?.join(', '), variant: "danger" });
			scrollToTop();

		}
        else {
			setFormErrors({});
			setIsSending(true);
            // if there is no file and no error then we are editing a video,
            if (!file) {
				formData.append("video_upload[fileSize]", videoToUpdate.fileSize);
				formData.append("video_upload[hash]", videoToUpdate.hash);
				formData.append("video_upload[videoSource]", videoToUpdate.videoSource);
				formData.append("video_upload[description]", editorContentRef.current);
				formData.append(`video_upload[releaseDate]`, HelperMetiers360.formatDateForFormControl(releaseDate));

				updateVideo({formData, uniqueId: videoToUpdate.uniqueId})
					.then(() => {
						setIsSuccess(true);
						navigate(
							readOneVideoSlug.replace(':uniqueId', videoToUpdate.uniqueId),
							{
								replace: true,
								state:  {fromEdit: true}
							})
					})
					.catch((error) => {
						setIsSending(false);
						handleBadRequest(error);
					})
				
            }
			// else we are create one, processing queue will fire the upload of video 
			// on the server, and then post the new video in DB
            else {
                dropzone.processQueue();
            }

        }
	}

	const handleBadRequest = (error) => {
		const errorList = {};
		if (error?.errors) {
			for (const key of Object.keys(error.errors)) {
				errorList[key] = error.errors[key]
			}
		}
		errorList.global = 'Il y a eu un probleme lors de l\'enregistrement de la video';
		setFormErrors(errorList);
		setIsSuccess(false);
		scrollToTop();
		
	}

	const scrollToTop = () => {
		window.scrollTo({
			top:0,
			behavior: 'smooth',
		})
	}

	const handleEditorChange = (content, editor) => {
		setEditorContent(content);
	}

	const handleLangChange = (index, langValue) => {
		const videoSubtitles = videoToUpdate?.subtitles ? videoToUpdate.subtitles.map(subtitle => subtitle.lang) : [];
		const tempSubtitleFileInputName = [...subtitleFileInputName];
		tempSubtitleFileInputName[index] = videoSubtitles.some(subtitle => subtitle === langValue) ? "Changer le fichier de sous-titres" : "Ajouter un fichier de sous-titres";
		setSubtitleFileInputName(tempSubtitleFileInputName); 
	}

	let dropzoneContent = '';
	dropzoneContent = <>
		<div id="dropzone-content" className="dropzone" style={{border: formErrors.media ? "2px solid #dc3545" : ""}}>

		</div>
	</>;

	return (
		<div>
	  		<div>
			  {isSuccess  ? <Alert variant="success">chargement terminé</Alert> : null}
			  { formErrors.global ? <Alert variant="danger"> {formErrors.global}</Alert> : null}

				<Row className="mb-3" >
					<Col>
						{dropzoneContent}
						<Form.Control
						type="hidden"
						isInvalid={formErrors.media !== undefined}
						/>
						<Form.Control.Feedback type='invalid'>{formErrors.media}</Form.Control.Feedback>
					</Col>
				</Row>

				<Form id="videoUploadData-form" method="post" encType="multipart/form-data">
					<Form.Group controlId="video_upload_videoName" className="formSection">
                        <Form.Label>
                            Nom <span style={{ color: videoName?.length > 50 ? "red" : "black" }}>({videoName?.length}/50)</span>
                        </Form.Label>
						<Form.Control
							name="video_upload[videoName]"
                            placeholder="Entrer le nom de l'expérience immersive"
							value={videoName}
							isInvalid={formErrors.videoName !== undefined}
							onChange={(e => setVideoName(e.target.value))}
                        />
						<Form.Control.Feedback type='invalid'>{formErrors.videoName}</Form.Control.Feedback>
					</Form.Group>

					<Form.Group controlId="video_upload_subHeading" className="formSection">
						<Form.Label>Titre secondaire</Form.Label>
						<Form.Control
							name="video_upload[subHeading]"
                            placeholder="Entrer le titre secondaire de l'expérience immersive"
							value={subHeading}
							isInvalid={formErrors.subHeading !== undefined}
							onChange={(e => setSubHeading(e.target.value))}
						/>
						<Form.Control.Feedback type='invalid'>{formErrors.subHeading}</Form.Control.Feedback>
					</Form.Group>

					<Form.Group controlId="video_upload_position" className="formSection">
						<Form.Label>Position</Form.Label>
						<Form.Control
							type="number"
							name="video_upload[position]"
							defaultValue={ videoToUpdate ? videoToUpdate.position : ""}
						/>
					</Form.Group>

					<Form.Group className="formSection">
						<Form.Label>Vignette ({fileContraints.IMAGE_MIME_TYPES_NAMES?.join(',')})  ({fileContraints.MAX_SIZE_IMAGE_MO} Mo max.)</Form.Label>
						<div className="input-group mb-4">
							<div className="input-group-prepend">
								<span className="input-group-text" id="inputGroupFileAddon01">
									{videoToUpdate && videoToUpdate.links && videoToUpdate.links.thumbnail ? <Image src={videoToUpdate.links.thumbnail} rounded thumbnail className={"max-width-200"} />:'Choisir la vignette'}
								</span>
							</div>
							<div className="custom-file">
								<input
									type="file"
									className="custom-file-input"
									id="inputGroupFile01"
									name="video_upload[thumbnailSource]"
									aria-describedby="inputGroupFileAddon01"
									accept={fileContraints.IMAGE_MIME_TYPES?.join(',')}
									onChange={(event)=> setThumbnailFileInputName(event.target.files[0].name)}
								/>
								<label className="custom-file-label" htmlFor="inputGroupFile01" data-browse="Parcourir">
									{thumbnailFileInputName}
								</label>
							</div>
						</div>
						<Form.Control
									type="hidden"
									isInvalid={formErrors.thumbnailSource !== undefined}
								/>
								<Form.Control.Feedback type='invalid'>{formErrors.thumbnailSource}</Form.Control.Feedback>
					</Form.Group>

					<Form.Group className="formSection">
						<Form.Label>Publication</Form.Label>
						<DatePicker
								showIcon
								locale='fr'
								dateFormat="dd/MM/yyyy"
								selected={releaseDate}
								onChange={(date) => {
										setFormErrors((prev) => ({...prev, releaseDate: null}));
										setReleaseDate(date);
									}
								} 
								placeholderText="jj/mm/aaaa"
                    	/>
					    {formErrors?.releaseDate && <div className='invalid-feedback' style={{display: "block"}}>Veuillez indiquer une date de publication</div>} 
						<input type="hidden" name="video_upload[releaseDate]" value={HelperMetiers360.formatDateForFormControl(releaseDate)} />
					</Form.Group>

					<Form.Group className="formSection">
						<Form.Check type='checkbox' id='video_direct_access_code' name='video_upload[isDirectlyAccessible]' inline>
							<Form.Check.Input
								type='checkbox'
								onChange={(event) => {
									setIsDirectlyAccessible(event.target.checked)
								}
								}
								name='video_upload[isDirectlyAccessible]'
								defaultChecked={isDirectlyAccessible}
							/>
							<Form.Check.Label> <span>Cochez si la video est directement accessible via un code</span></Form.Check.Label>
						</Form.Check>
					</Form.Group>

					<Form.Group className="formSection">
						<Form.Check type='checkbox' id='video_upload_private' name='video_upload[private]' inline>
							<Form.Check.Input
								type='checkbox'
								onChange={ (event) => {
									if (event.target.checked)
										setIsPrivateVideo(true)
									else
										setIsPrivateVideo(false)
									}
								}
								name='video_upload[private]'
								defaultChecked={isPrivateVideo}
							/>
                            <Form.Check.Label> <span>Cochez s'il s'agit d'une expérience immersive privée</span> <i className={isPrivateVideo ? "fas fa-lock" : "fas fa-lock-open"}> </i> </Form.Check.Label>
						</Form.Check>
					</Form.Group>

					<Form.Group className="formSection">
						<Form.Check type='checkbox' id='video_upload_isAlwaysVisible' name='video_upload[isAlwaysVisible]' inline>
							<Form.Check.Input
								type='checkbox'
								onChange={ (event) => {
										if (event.target.checked){setIsAlwaysVisible(true)} else {setIsAlwaysVisible(false)}
									}
								}
								name='video_upload[isAlwaysVisible]'
								defaultChecked={isAlwaysVisible}
							/>
                            <Form.Check.Label>Cochez s'il s'agit d'une expérience immersive freemium, accessible sans abonnement pour tous les clients.</Form.Check.Label>
						</Form.Check>
					</Form.Group>

					<Form.Group  className="formSection">
						<Form.Label>Producteur(s)</Form.Label>
						<SelectMultipleClientsComponent
							nameSelect={`video_upload[producers][]`}
							selectedClients = {selectedClients && selectedClients.length ? selectedClients:[]}
							setSelectedClients = {setSelectedClients} />
            		</Form.Group>


					<Form.Group  className="formSection">
						<Form.Label>Description</Form.Label>
						<Editor
							name="video_upload[description]"
							initialValue={videoToUpdate?.description ? videoToUpdate.description : ""}
							apiKey="t5rspxvw6u2zr48fduj1kf3twvxk7dsncf5bk8h50v07s8lg"
							init={{
							height: 500,
							menubar: false,
							plugins: [
								'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor',
								'searchreplace', 'visualblocks', 'code', 'fullscreen',
								'insertdatetime', 'media', 'table', "code", 'help', 'wordcount', 'emoticons'
							],
							toolbar:
								'undo redo | bold italic backcolor | \
								alignleft aligncenter alignright alignjustify | \
								bullist numlist outdent indent | charmap emoticons | link | removeformat | help'
							}}
							onEditorChange={handleEditorChange}
						/>
						{/* <Form.Control as="textarea" rows="5" name="video_upload[description]" defaultValue={videoToUpdate.description}/> */}
					</Form.Group>
					
					<Form.Group className="subtitles-section formSection">
					
						<Form.Label>Sous-titre</Form.Label>
						
						{(videoToUpdate && videoToUpdate.subtitles?.length) ? 
							<div className="existing-subtitles">
								<p>Sous-titres existants:</p>
								{videoToUpdate.subtitles.map((subtitle)=> <div key={subtitle.url} className="subtitle-card">
										<a href={subtitle.url}>{subtitle.lang}</a>
								</div>)}
							</div>
						: null
						}

						{ Array.from({length: countSubtitles}, (_,index) => index).map((num,i) => { 
							return(
							<div key={`inputgroup${i}`}>
								<div className="select-lang">
									<label htmlFor={`languageSelect${i}`}>Uploader un fichier .srt en</label>
									<select
									id={`languageSelect${i}`}
									value={subLangSelected[i]}
									onChange={(e) => {
										let tempSubLang = [...subLangSelected];
										tempSubLang[i] = e.target.value;
										setSubLangSelected(tempSubLang);
										handleLangChange(i, e.target.value);
									}}
									>
										<option value="">Sélectionner la langue du fichier</option>
										{allLanguages.filter((language) => language.key === subLangSelected[i] || !subLangSelected.includes(language.key)).map((language,k) => (
											<option key={`language.key${k}`} value={language.key}>
											{language.description}
											</option>
										))}
									</select>
								</div>
							{ subLangSelected && subLangSelected[i] ?
								<div className="custom-file">
									<input
										type="file"
										className="custom-file-input"
										id={`inputGroupFile02${i}`}
										name={`video_upload[subtitles_${subLangSelected[i]}]`}
										aria-describedby="inputGroupFileSubtitle"
										accept=".srt"
										onChange={(event)=> {
											let tempSubTitle = [...subtitleFileInputName];
											tempSubTitle[i] = event.target.files[0].name;
											setSubtitleFileInputName(tempSubTitle);
										}}
									/>
									<label className="custom-file-label" htmlFor={`inputGroupFile02${i}`} data-browse="Parcourir">
										{subtitleFileInputName[i]}
									</label>
									{videoToUpdate && videoToUpdate.subtitles?.length && videoToUpdate.subtitles.some(subtitle => subtitle.lang === subLangSelected[i]) && subtitleFileInputName[i].includes('.srt') ? <p style={{color: "red", fontSize: "0.8em"}}>Ce fichier remplacera celui déjà existant</p> : null}
								</div>
								
								: null
							}
							</div>
						);})}

						{(subLangSelected?.length && subLangSelected.length === countSubtitles && countSubtitles < allLanguages.length) ?
							<Button 
								variant="light"
								onClick={() => {
									if (countSubtitles < allLanguages.length){setCountSubtitles((prev)=>prev += 1)}
								}
							}>+</Button>
						:null}

					</Form.Group>

				<Form.Group className="formSection">	
					<SelectMultipleItemsWithSearchBar idSelector="video_upload[rome][]" label="Codes ROME"
						allItems={allRomes} 
						selectedItemKeys={selectedRomes} setSelectedItemKeys={setSelectedRomes} 
						itemKey="codeRome" itemValue="codeRome" itemValueDescription="label" />
				</Form.Group>

                {allGroups.length > 0 &&
                    <Form.Group  className="formSection">	
						<SelectMultipleItemsWithSearchBar idSelector="video_upload[group][]" label="Groupes à associer"
							allItems={allGroups.filter(g => !g.isSpecial)} 
							selectedItemKeys={selectedGroups} setSelectedItemKeys={setSelectedGroups} 
							itemKey="uniqueId" itemValue="name" />
					</Form.Group>
                }

				<Form.Group >
					<Form.Control
						name="video_upload[_token]"
						defaultValue={token}
						type="hidden"
					/>
				</Form.Group>

				</Form>

				<br/>

				<ButtonLoaderComponent
					isSending={isSending}
                    disabled={isSending}
					active={!isSending}
					variant="secondary"
					onClick={checkData}
					msg={videoToUpdate && videoToUpdate.uniqueId ? <><i className="fas fa-save"/> &nbsp;Éditer</>:<><i className="fas fa-save"/> &nbsp;Sauvegarder</>}
				/>
			</div>
		</div>
	);
};

export default UploadVideoComponent;
