import React from "react";
import { Container, Image } from "react-bootstrap";
import PropTypes from 'prop-types';
import './EditableImageComponent.scss'; 

const EditableImageComponent = ({ src, onEdit, isEditable = false }) => {
    return (
        <Container className="editable-image-container">
            <Image src={src} onClick={isEditable ? onEdit : null} className={"img-editable " + (isEditable ? "cursor-pointer" : "")} thumbnail />
            {isEditable && <i className="fas fa-edit edit-icon" onClick={onEdit} />}
        </Container>
    );
}

EditableImageComponent.propTypes = {
    src: PropTypes.string.isRequired,
    onEdit: PropTypes.func.isRequired,
    isEditable: PropTypes.bool
};

export default EditableImageComponent;
