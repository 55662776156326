import React from 'react';
import { Routes, Route, Link, useLocation } from 'react-router-dom';
import { Row, Col, Container, Card, Alert } from "react-bootstrap";
import { useStoreState } from 'easy-peasy';

import logo from '../../images/logo140.png';
import './Login.scss';
import Login from './Login';
import Register from './Register';
import Reset from './Reset';
import Loader from '../../components/widgets/Loader';
import GeneralTermsAndConditionsOfUse from './GeneralTermsAndConditionsOfUse';

const Unlogged = () => {
    const { sharedSlugs, isFetchNecessary: isFetchParamsNecessary } = useStoreState(state => state.params);
    const location = useLocation();

    return location.pathname === "/generalTermsAndConditionsOfUse"
        ? <GeneralTermsAndConditionsOfUse />
        : <Container fluid id="layoutAuthentication">
            <Row id="layoutAuthentication_content" className='background-img'>
                <Col className="form-dispo">
                    <Col md={7} lg={5} className="form-dispo-content">
                        <Card>
                            <Card.Header>
                                <img src={logo} alt='logo' width='100px' height='100px' />
                            </Card.Header>
                            <Card>
                                <Card.Body>
                                    {isFetchParamsNecessary ?
                                        <Loader />
                                        : <Routes>
                                            <Route path={"/".concat(sharedSlugs?.activation, "/:inviteToken")}
                                                element={<Register />} />
                                            <Route path={"/".concat(sharedSlugs?.reset, "/:resetToken")}
                                                element={<Reset />} />
                                            <Route path="*" element={<Login />} />
                                        </Routes>
                                    }
                                </Card.Body>
                                <Card.Footer>
                                    <a target="_blank" href='https://metiers360.com/' rel="noreferrer noopener">Vous souhaitez en savoir plus ?</a>
                                </Card.Footer>
                            </Card>
                        </Card>
                    </Col>
                </Col>
            </Row>
            <Row id="layoutAuthentication_footer">
                <Col className="layout-footer">
                    <Col className="text-left">Copyright &copy; Metiers360 2024</Col>
                    <Col className="text-right">
                        <a href="#!">Politique de confidentialité</a>
                        &nbsp;&middot;&nbsp;
                        <a href="#!">Conditions d'utilisation</a>
                    </Col>
                </Col>
            </Row>
        </Container>
}

export default Unlogged;