import { useStoreState } from 'easy-peasy';
import React, { useState, useEffect, useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import axios from '../../services/axios.js';
import ActivitiesCard from './ActivitiesCard.jsx';
import Loader from '../widgets/Loader.jsx';
import PropTypes from 'prop-types';

import './ActivitiesPanel.scss';

const ActivitiesNumbers = (props) => {

    const { clientsUniqueId = [], setIsFetching, isFetching, oneMonth, fromStatsPage = false } = props;

    const [activity, setActivity] = useState(0);

    const { apiData: { URLs } } = useStoreState(state => state.actionSlugs);

    const postUrl = URLs?.getActivityNumbers?.url;

    const timeoutDelay = fromStatsPage ? 1000 : 0;

    const isFirstRender = useRef(true);

    const updateData = () => {
        axios.post(postUrl, { uuid: clientsUniqueId, oneMonth }, { responseType: "json" })
            .then((response) => {
                setActivity(response);
            }).finally(() => setIsFetching(false));
    }

    useEffect(() => {
        if (clientsUniqueId?.length > 0 && !isFetching) {
            const timeoutId = setTimeout(() => {
                setIsFetching(true);
                updateData();
            }, timeoutDelay);

            return () => clearTimeout(timeoutId);
        } else {
            setActivity(0);
        }
    }, [clientsUniqueId]);


    useEffect(() => {

        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }

        if (clientsUniqueId?.length > 0 && !isFetching) {
            setIsFetching(true);
            updateData();
        } else {
            setActivity(0);
        }
    }, [oneMonth]);

    return (
        isFetching ?
            <Loader />
            :
            <div className="welcome-card numbers-banner">
                <h1>Usages {!!oneMonth && " sur les 30 derniers jours"}</h1>
                    <Row>
                        <Col>
                            <ActivitiesCard
                                activityName={'Stories vues'}
                            activityData={activity.webappViews ?? '-'} key={'webappViews'}
                            />

                        </Col>

                        <Col>
                            <ActivitiesCard
                                activityName={'Expériences immersives VR vues'}
                            activityData={activity.headsetViews ?? '-'} key={'headsetViews'}
                            />
                        </Col>

                        <Col>
                            <ActivitiesCard
                                activityName={'Casques utilisés'}
                            activityData={activity.activeHeadset ?? '-'} key={'activeHeadset'}
                            />
                        </Col>
                    </Row>
            </div>
    )
}

ActivitiesNumbers.propTypes = {
    clientsUniqueId: PropTypes.array,
    setIsFetching: PropTypes.func,
    isFetching: PropTypes.bool,
    oneMonth: PropTypes.bool,
    fromStatsPage: PropTypes.bool
};

export default ActivitiesNumbers;