import DynamicTable from "../../components/widgets/dynamicTable/DynamicTable"
import "./GeneralTermsAndConditionsOfUse.scss";
import logo from '../../images/logo140_squared.png';
import { useStoreState } from "easy-peasy";

const GeneralTermsAndConditionsOfUse = () => {
    const { isLogged } = useStoreState(state => state.auth);

    return <>
        {!isLogged && <div id="cgu-header">
            <div className='logo-container'>
                <img
                    src={logo}
                    id="logo"
                    alt='metiers360'
                />
            </div>
        </div>}
        <div id={isLogged ? "" : "cgu-body"}>
            <h2>Conditions générales d'utilisation</h2>
            <i>À jour du 12/12/2024</i>
            <br />
            <br />
            <p>
                Nous sommes soucieux de la <b>protection des données personnelles</b> de nos clients et usagers.<br />
                Le site <a href="https://backoffice.metiers360.com/" target="_blank">https://backoffice.metiers360.com/</a> (ci-après "Le site") est susceptible de collecter certaines de vos données personnelles
                en tant qu’utilisateur. La présente charte a pour objet d’exposer les conditions dans lesquelles les données personnelles collectées
                sont traitées et utilisées, afin d’informer aux mieux les personnes concernées.<br />
                Nous nous engageons à mettre en oeuvre le meilleur niveau de protection de vos données personnelles en conformité avec
                le <b>Règlement Général sur la Protection de Données Personnelles</b>.<br />
                Pour toute information sur la protection des données personnelles, vous pouvez également consulter <b>le site de la Commission
                    Informatique et Liberté (CNIL)</b> <a href="https://www.cnil.fr/fr/reglement-europeen-protection-donnees/" target="_blank">https://www.cnil.fr/fr/reglement-europeen-protection-donnees/</a><br />
            </p>
            <h4>1. Identité du responsable de traitement</h4>
            <p>
                <b>Plus Éditions SAS, société éditrice de Métiers360<br />
                    Vincent Olivier
                16, rue Bleue<br />
                    75009 Paris<br /></b>
                <br />
                RCS Paris : 480 590 645<br />
                Numéro d’identification TVA intracommunautaire : FR31480590645<br />
                <b>Adresse mail de contact</b> : <a href="mailto:rgpd@metiers360.com">rgpd@metiers360.com</a>
            </p>
            <h4>2. Respect de la loi du 6 janvier 1978 dite «Loi informatique et libertés» et du règlement UE/2016/679</h4>
            <p>
                Le responsable de traitement est garant du traitement de vos données à caractère personnel et s’engage à respecter
                la <b>loi n°78-17 du 6 janvier 1978</b> relative à l'informatique, aux fichiers et aux libertés, dite <b>Loi « Informatique et Libertés »</b>.
            </p>
            <h4>3. Prise de connaissance de la charte</h4>
            <p>
                En utilisant notre site, vous déclarez avoir pris connaissance de la politique du responsable de traitement relative au traitement et à
                la protection des données personnelles.<br />
                Vous ne pourrez pas créer votre compte personnel sur le site si vous refusez de fournir les informations nécessaires sollicitées dans le
                formulaire d’inscription.<br />
                Si vous souhaitez que nous supprimions vos données de notre base, il vous suffit de nous contacter par e-mail ou par courrier,
                aux adresses indiquées au point 1. Nous nous engageons à traiter votre demande dans les plus brefs délais.<br />
            </p>
            <h4>4. Principes généraux</h4>
            <p>
                L'exercice de notre métier nous amène à conserver des données qui vous sont personnelles.<br />
                La conservation de vos données personnelles est mise en oeuvre dans le respect de différents principes.<br />
                <ul>
                    <li>
                        <b>Limitation des finalités</b> : les données ne sont collectées que pour des raisons et des finalités déterminées, nécessaires,
                        explicites et légitimes.
                    </li>
                    <li>
                        <b>Minimisation des données en fonction des finalités</b> : les données collectées sont limitées à ce qui est nécessaire et
                        pertinent au regard des finalités pour lesquelles elles sont traitées. Nous ne vous demanderons pas des données qui seraient
                        inutiles au service et à la qualité du service que nous vous devons.
                    </li>
                    <li>
                        <b>Limitation de la conservation dans le temps</b> : Vos données personnelles ne seront conservées sous une forme permettant
                        l'identification des personnes que le temps nécessaire à la poursuite de la finalité pour laquelle elles ont été collectées.
                        Passée cette limite, vos données seront supprimées ou rendues anonymes. A chaque fois que possible, elles seront
                        pseudonymisées.
                    </li>
                    <li>
                        <b>Loyauté et transparence</b> : la mise en oeuvre des traitements de vos données est effectuée de manière loyale et transparente,
                        notamment une information enrichie pour chacun des traitements listés ci-dessous. De même dans nos rapports avec vous, nous
                        nous efforçons d'être le plus clair possible.
                    </li>
                    <li>
                        <b>Exactitude</b> : Nous faisons en sorte que vos données soient exactes. De ce fait nous vous demanderons parfois de nous préciser
                        d'éventuelles mises à jour si nécessaire.
                    </li>
                    <li>
                        <b>Protection, intégrité et confidentialité</b> : les données à caractère personnel sont traitées de façon à garantir un niveau
                        de sécurité adéquat. Des mesures techniques ou organisationnelles appropriées sont donc mises en place.
                    </li>
                    <li>
                        <b>Information</b> : nous vous donnons accès à un responsable de traitement en mesure de vous répondre directement.
                    </li>
                </ul>
            </p>
            <h4>5. Protection</h4>
            <p>Sur tous nos sites, votre <b>mot de passe d'accès est encrypté</b>.</p>
            <h4>6. Principes contre la fouille de textes et de données</h4>
            <p>
                Métiers360 s’oppose à toutes opérations de moissonnage et de fouille de textes et de données au sens de l’article <b>L. 122-5-3</b> du code
                de la propriété intellectuelle.<br />
                Cette opposition couvre l’ensemble du site et des contenus auxquels il donne accès.<br />
                Toutes opérations de moissonnage et de fouille de textes et de données visant le site et ses contenus, y compris par des dispositifs
                de collecte automatisée de données, constituent donc des actes de contrefaçon sauf obtention d’un accord spécifique formellement
                exprimé de Métiers360. L’article <b>R. 122-28</b> du code de la propriété intellectuelle précisant que l'opposition mentionnée au III de
                l'article <b>L. 122-5-3</b> peut être exprimée par tout moyen, y compris par le recours à des conditions générales d'utilisation ou
                mentions légales d'un site internet ou d'un service, l’absence de métadonnées associées au site, répertoires du site, contenus du
                site est sans incidence sur l’exercice du droit d’opposition exprimé par les présentes conditions générales d’utilisation.
            </p>
            <h4>7. Bases légales</h4>
            <p>
                Dans le respect de ces principes, le traitement de vos données personnelles se fait sur l’un des fondements suivants :<br />
                <b><ul>
                    <li>Votre consentement pour une ou plusieurs finalités.</li>
                    <li>La bonne exécution contractuelle de nos services et conditions générales de vente.</li>
                    <li>Le respect d'une obligation légale imposée par la législation de l'Union européenne ou d'un État membre.</li>
                    <li>La sauvegarde de vos intérêts.</li>
                    <li>L'intérêt légitime du responsable de traitement. Dans ce cas, il nous incombe de vous l'expliquer de façon simple et claire.</li>
                </ul></b>
            </p>
            <h4>8. Méthodes de collecte</h4>
            <p>
                Le Responsable de traitement peut recueillir des informations vous concernant à différents moments, notamment quand :<br />
                <b><ul>
                    <li>vous remplissez un <b>formulaire d'inscription</b> pour créer votre compte utilisateur client</li>
                    <li>vous <b>naviguez</b> sur notre site et <b>modifiez</b> ou <b>supprimez</b> des données</li>
                    <li>vous utilisez notre logiciel dans des <b>casques VR</b></li>
                    <li>vous <b>contactez notre SAV</b></li>
                </ul></b>
            </p>
            <h4>9. Informations collectées</h4>
            <p>
                Les informations pouvant être collectées sont les suivantes:<br />
                <b><ul>
                    <li>nom</li>
                    <li>prénom</li>
                    <li>fonction au sein d'une structure</li>
                    <li>adresse email</li>
                    <li>mot de passe</li>
                    <li>numéro de téléphone</li>
                    <li>numéro de mobile</li>
                    <li>adresse de livraison</li>
                    <li>adresse IP</li>
                    <li>données de connexions</li>
                    <li>historiques d'actions</li>
                </ul></b>
                Certaines données sont collectées automatiquement du fait de vos actions sur le site.<br />
                Nous effectuons également des mesures d'audience anonymisées (au bout d'un an - voir point 14), nous mesurons par exemple le nombre de pages vues, le nombre de visites
                du site, ainsi que l'activité des visiteurs sur le site et leur fréquence de retour.
            </p>
            <h4>10. Destinataires des données</h4>
            <p>
                Les données et informations collectées sur l’utilisateur sont destinées au responsable de traitement.<br />
                <b>Aucune vente</b> ne sera faite de ces données collectées.<br />
                Le responsable de traitement se réserve le droit de transférer vos données à caractère personnel dans le cas d’un changement de son
                contrôle ou de la cession de tout ou partie de ses actifs, notamment par acquisition ou fusion avec une autre société.<br />
                <b>Néanmoins, vos données peuvent être transmises aux autorités compétentes sur requête et notamment aux organismes publics,
                exclusivement pour répondre aux obligations légales, les auxiliaires de justice, les officiers ministériels et les organismes chargés
                    d’effectuer le recouvrement de créances.</b>
            </p>
            <h4>11. Dans l'exercice spécifique de notre métier les finalités principales sont:</h4>
            <p>
                <b>La création de votre compte pour accéder aux services du site et assurer la bonne gestion de vos données</b>
                <br />
                Les informations et données vous concernant sont regroupées dans la page « Mes données » - onglet « Utilisateur⋅rices ».
                Ces informations pourront aussi être conservées aux fins de maintien de vos historiques d'actions et de preuve dans le respect de nos
                obligations légales et réglementaires (paiement, garantie, litige, respect du règlement sur la protection des données).
                Les informations et données personnelles vous concernant sont accessibles sur cette même page.
                <br />
                <br />
                <b>L'envoi de mail et newsletters</b>
                <br />
                Nous vous envoyons des mails qui peuvent être classés en 3 types :
                <ul>
                    <li>Mail de double opt in : pour confirmer votre inscription.</li>
                    <li>Mail d'échange : dans le cadre du suivi ou en SAV</li>
                    <li>Newsletter générale : vous pouvez vous désinscrire à tout moment en cliquant sur le lien en bas de mail</li>
                </ul>
                <b>La personnalisation de nos services et de nos offres</b>
                <br />
                Nous n'utilisons pas vos coordonnées pour vous envoyer ou vous faire envoyer de la publicité.<br />
                Les informations et données vous concernant nous permettent d'améliorer et de personnaliser les services que nous vous proposons
                notamment sur notre site internet et les informations que nous vous adressons.<br />
                Afin de vous tenir informés de notre actualité et des avantages dont vous pouvez bénéficier, vous pourrez recevoir des informations
                et des offres par communication électronique.
                <br />
                <br />
                <b>Anonymisation ou Pseudonymisation de vos données</b>
                <br />
                A chaque fois que possible nous privilégierons l'anonymisation et la pseudonymisation de vos données.
                Ainsi par exemple vos <b>mots de passe sont encryptés</b> et nous n'en avons pas connaissance.
                Vous trouverez ci-dessous une description des finalités principales concernant l'utilisation de vos données, puis une liste détaillée
                de chaque traitement spécifique.<br />
                Nous en limitons l'usage en qualité et en quantité, de même que nous en limitons la conservation dans le temps en fonction de chaque usage.
                <br />
                <br />
                <b>Exercice des droits</b>
                <br />
                Vous pouvez demander l’accès, la rectification, la limitation de leur usage ou l’effacement de vos données.<br />
                Pour ces démarches ou tout renseignement, vous pouvez également nous contacter à
                l’adresse <a href="mailto:rgpd@metiers360.com">rgpd@metiers360.com</a><br />
                Si vous considérez que l’utilisation de ces données est abusive, vous pouvez porter réclamation auprès de <b>la CNIL</b>,
                via son site internet (<a href="https://www.cnil.fr/fr/plaintes" target="_blank">https://www.cnil.fr/fr/plaintes</a>).
                La technologie évoluant, le responsable de traitement pourrait être amené à ajouter des finalités qui ne sont pas encore prévues
                dans cette charte. Nous publierons toute modification des règles de confidentialité sur cette page et, dans le cas où il s’agirait
                de modifications significatives, nous publierons un avertissement mis en évidence.
            </p>
            <h4>12. Liste des traitements</h4>
            <DynamicTable
                contentTable={[
                    {
                        id: "1",
                        name: "Enregistrement de vos coordonnées",
                        end: "Création de votre compte client",
                        description: "Création de votre espace personnel afin de vous permettre d'avoir accès à nos différents services et particulièrement d'avoir accès au catalogue de nos expériences immersives, à vos données de structure (abonnements, casques VR, utilisateur⋅rices), à des statistiques, à la gestion de playlists, vos codes webApp, aux modules pédagogiques, webinaires, à la base métiers, à l'inscription au Lab, à l'article de mise à jour de casque VR, à la base de connaissance et à nous contacter en SAV .."
                    },
                    {
                        id: "2",
                        name: "Enregistrement de traces d’utilisation courantes",
                        end: "Statistiques",
                        description: "Les données suivantes : usage des casques, création/modification d’utilisateur, modification des casques, logs de connexion sont collectées pour nourir nos outils statistiques. Ces traces sont conservées pendant au moins un an. Toutes les données personnelles soumises au RGPD sont anonymisées un an après la sortie du dispositif de l’organisation dont la personne dépend."
                    }
                ]}
                contentSort={[
                    {
                        value: 'name', label: 'Nom du traitement', test: true,
                        display: (content) => content.name
                    },
                    {
                        value: 'end', label: 'Finalité', test: true,
                        display: (content) => content.end
                    },
                    {
                        value: 'description', label: 'Description', test: true,
                        display: (content) => content.description
                    }
                ]}
                index='id'
                enableToControlVisibleColumns={false}
                downloadable={false}
            />
            <h4>13. Cookies</h4>
            <p>Ce site ne récolte <b>aucun cookie tiers</b>. Si vous souhaitez en savoir plus sur ce qu'est
                un cookie : <a href="https://www.cnil.fr/fr/definition/cookie" target="_blank">lien CNIL</a>.</p>
            <h4>14. Durée de conservation</h4>
            <p>
                Les données sont conservées par le responsable de traitement pendant la <b>durée nécessaire aux finalités</b> pour lesquelles elles ont été
                collectées. Il est précisé en particulier que:
                <ul>
                    <li>
                        pour la gestion de votre <b>compte personnel</b>, les données sont conservées tant que votre compte est actif et pendant <b>1 an après
                            la dernière connexion</b> au site via votre compte personnel, puis sont <b>anonymisées</b>.
                    </li>
                    <li>
                        pour la gestion des <b>traces d'usage</b>, les données sont conservées pendant <b>1 an</b>, puis sont <b>anonymisées</b>.
                    </li>
                </ul>
            </p>
            <h4>15. Droit d’accès, de rectification et d’opposition</h4>
            <p>
                Vous disposez d'un <b>droit d'accès, de rectification, d’opposition et de suppression</b> concernant vos données personnelles.<br />
                De façon générale, vous pouvez exercer ces droits à tout moment par courrier postal ou courriel à l'adresse indiquée au <b>point 1</b>.<br />
                Toute demande sera traitée dans un <b>délai maximum de 1 mois</b> suivant réception.<br />
                Si vous vous êtes abonné à la newsletter du site, vous pouvez à tout moment vous désabonner en cliquant sur le lien situé en bas de
                chaque e-mail que vous recevez.<br />
                Vous pouvez nous demander de vous envoyer l'ensemble de vos données.<br />
                Vous avez également le droit d’introduire une réclamation auprès de :<br />
                <b>Commission Nationale de l’Informatique et des Libertés (CNIL)</b><br />
                3 Place de Fontenoy<br />
                TSA 80715 - 75334 PARIS CEDEX 07<br />
                Tél : 01 53 73 22 22<br />
                <a href="https://www.cnil.fr/fr/vous-souhaitez-contacter-la-cnil" target="_blank">https://www.cnil.fr/fr/vous-souhaitez-contacter-la-cnil</a>
            </p>
            <h4>16. Sécurité</h4>
            <p>
                Le responsable de traitement fait ses meilleurs efforts afin de préserver la confidentialité et la sécurité des données traitées et
                empêcher qu’elles ne soient déformées, endommagées, détruites ou que des tiers non autorisés y aient accès.<br />
                Cependant, le responsable de traitement ne maîtrise pas tous les risques d’intrusion et de piratage inhérents au fonctionnement et à
                l’utilisation d’internet, pour lesquels elle ne saurait être tenue responsable.<br />
                Pour votre information, sur notre site, votre mot de passe d'accès est encrypté.<br />
                A chaque fois que possible, nous privilégierons l'anonymisation et la pseudonymisation de vos données.
            </p>
        </div>
    </>
}
export default GeneralTermsAndConditionsOfUse;