import React, { useState, useEffect } from "react";
import { Form, Container, Row } from 'react-bootstrap';
import CardGroupMediaComponent from "../../../widgets/CardGroupMedia/CardGroupMediaComponent";
import AuthorizationChecker from "../../../../services/AuthorizationChecker.js";
/**
 * 
 * @param {Array} formations array of all formations to be rendered
 * @param {Array} sortTypePreferenceTuple Optionnal -  state and action from store to handle sort type preference
 *  [
 *      formationBoardSortType: value from store,
 *      setFormationSortType: actionCreator
 *  ]
 * @param {String} readAll  readAll slug to access the formation list page
 * @param {String} readOne  readOne slug to access a formation details page - replace the :uniqueId part of string with actual uniqueId
 * @example
 *  <FormationsBoardComponent formations={formations} sortTypePreferenceType={[string, actionCreator]} readAll={readAllSlug} create={createSlug} {...}/>
 */

const FormationsBoardComponent = (props) => {
    const { formations, readOne: readOneSlug, sortTypePreferenceTuple } = props;

    const [formationBoardSortType, setFormationsBoardSortType ] = sortTypePreferenceTuple ? sortTypePreferenceTuple : [null, null];
    const sortByDateMessage = formations[0]?.hasOwnProperty('nextSession') ? "Prochaine session (plus récente d'abord)" : "Date de publication (plus récente d'abord)";
    const sortByDateType = (a,b) => {
        if(formations[0]?.hasOwnProperty('nextSession')){
           return (a?.nextSession!== null ? new Date(a?.nextSession) : Infinity) - (b?.nextSession!== null ? new Date(b?.nextSession) : Infinity)
        }else{
            return (a?.releaseDate!== null ? new Date(a?.releaseDate) : Infinity) - (b?.releaseDate!== null ? new Date(b?.releaseDate) : Infinity)
        }
    }
    const sortType = [
        { value: 'name', label: 'Nom', method: (a, b) => a.name.localeCompare(b.name) },
        { value: 'date', label: sortByDateMessage, method: (a, b) => sortByDateType(a, b) },
    ];

    const [sort, setSort] = useState(formationBoardSortType ? sortType.find(type => type.value === formationBoardSortType) : sortType[0]);
    const [sortedFormations, setSortedFormations] = useState(formations.sort(sort.method));

    useEffect(() => {
        setSortedFormations([...formations.sort(sort.method)]);
    }, [sort, formations])

    const sortChoice = (e) => {
        const sortSelected = sortType.filter(type => type.value === e.target.value)[0];
        setSort(sortSelected);
        // eslint-disable-next-line no-unused-expressions
        setFormationsBoardSortType ? setFormationsBoardSortType(sortSelected.value) : null;
    };

    const checkSort = sortType.map(
        type => <Form.Check inline name="sort" label={type.label} type='radio' value={type.value} key={type.value} id={type.value} onChange={sortChoice} checked={sort.value === type.value} />
    );

    return (
        <Container fluid>
            {formations && formations?.length > 1 &&
            <Row className="mb-4">
                    <div className="d-flex justify-content-around check-sort">{checkSort}</div>
            </Row>
            }
            <Row>
                <CardGroupMediaComponent mediaType='formation' mediaList={sortedFormations} readOne={readOneSlug} />
            </Row>
        </Container>


    );
}
export default FormationsBoardComponent;