import React, { useState, useEffect } from "react";

import { Container, Row, Col, Button, Form, Alert, Card, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { useStoreState } from 'easy-peasy';


import {URLS} from '../../services/axios';

import AuthorizationChecker from '../../services/AuthorizationChecker';

import ActivitiesNumbers from '../../components/activities/ActivitiesNumbers';
import VideoPlayGraph from '../../components/activities/VideoPlayGraph';
import TooltipInfo from '../../components/widgets/tooltipInfo/TooltipInfo';
import useCustomGetStoreState from "../../hooks/useCustomGetStoreState";
import SelectMultipleClientsComponent from "../../components/widgets/selectClient/SelectMultipleClientsComponent";
import Loader from "../../components/widgets/Loader";
import useFreshworkWidget from '../../hooks/useFreshworksWidget.jsx';

import "./statsPage.scss";

const StatPage = () => {
    const {client:userClientUniqueId} = useStoreState(state => state.auth);
    const {apiData} = useStoreState(state => state.actionSlugs);
    const { initialFetchDone } = useStoreState(state => state.clients);

    const { allClients } = useCustomGetStoreState('clients');

    const [isFetching, setIsFetching] = useState(false);
    const [isFetchingNumbers, setIsFetchingNumbers] = useState(false);
    const [selectedClients, setSelectedClients] = useState([]);
    const [oneMonth, setOneMonth] = useState(true);

    const [isExpand, setIsExpand] = useState(false);

    const { openWidgetContactForm } = useFreshworkWidget();

    useEffect(() => {
        setSelectedClients(AuthorizationChecker.isAdmin() ? [] : allClients?.map(c => c.uniqueId));
    }, [allClients]);

    const toggleOneMonth = [
        { label: "30 derniers jours", value: true, defaultChecked: true },
        { label: "Depuis le début", value: false, defaultChecked: false }
    ];

    const downloadCSV = () => {
        const urlCSV = URLS.API_URL + apiData.URLs.getHeadsetActivityByCSV.url.replace('ClientID',userClientUniqueId).replace('activityID','all');
        window.open(urlCSV);
    }

    const downloadwebappActiviyCSV = () => {
        const urlCSV = URLS.API_URL + apiData.URLs.getwebappActivityByCSV.url.replace('ClientID',userClientUniqueId);
        window.open(urlCSV);
    }

    const RowActivityButton = 
    <Row>
        <Col className="mb-3 d-flex justify-content-around align-items-center">
            <Button onClick={downloadCSV}><i className="fas fa-download"/> Activités casques</Button>
            <Button onClick={downloadwebappActiviyCSV}><i className="fas fa-download"/> Activités webapp</Button>
        </Col>
    </Row>;

    return (initialFetchDone ?
        <Container fluid>

            <Row>
                <Col>
                    <h2 className='d-flex'>
                        Quelques chiffres
                        {!AuthorizationChecker.isAdmin()
                            && <TooltipInfo description='Quelles sont les expériences immersives les plus regardées sur votre parc de casques. 
                            Télécharger le détail au format csv.' />}
                    </h2>
                </Col>
            </Row>
            <Row>
                <Alert variant="warning">Seules les données issues de casques mis à jour sont disponibles, si un casque n'a pas été connecté à notre système depuis, ses usages ne sont pas inclus dans les chiffres présentés.</Alert>
            </Row>
            {!AuthorizationChecker.isAdmin() && RowActivityButton}

            {allClients?.length > 1 && <>
                <Row className="stats-select-clients">
                    <Card className="contents_details_card">
                        <Card.Title className="contents_details_title d-flex justify-content-between mx-4 cursor-pointer"
                            onClick={() => setIsExpand(prev => !prev)}>
                            {selectedClients?.length +
                                (selectedClients?.length > 1
                                    ? " établissements sélectionnés"
                                    : " établissement sélectionné")
                                + " pour analyse"}
                        </Card.Title>
                        <Card.Body className={"contents_details_body" + (isExpand ? "" : " visually-hidden")}>
                                <SelectMultipleClientsComponent
                                    disabled={isFetching || isFetchingNumbers}
                                    nameSelect={`stats[clients][]`}
                                    selectedClients={selectedClients}
                                setSelectedClients={setSelectedClients} />
                        </Card.Body>
                    </Card>
                </Row>
                <div className="expand-btn cursor-pointer" onClick={() => setIsExpand(prev => !prev)}>
                    {isExpand ? <i className="fa fa-angle-up fa-lg" /> : <i className="fa fa-angle-down fa-lg" />}
                </div>

            </>
            }

            <Row className="row-allcenter">
                <div className="d-flex align-items-center justify-content-between w-auto">
                    <ToggleButtonGroup className='py-2' type="radio" name="oneMonthCheck" id="oneMonthCheckSelect" value={oneMonth}
                        onChange={() => setOneMonth((prev) => !prev)}>
                        {toggleOneMonth.map(data =>
                            <ToggleButton value={data.value} key={data.label} id={data.label} checked={oneMonth} className='mb-0' disabled={isFetching || isFetchingNumbers}>
                                {data.label}
                            </ToggleButton>
                        )}
                    </ToggleButtonGroup>
                </div>
            </Row>
            {
                !allClients?.length && initialFetchDone ?
                    <Alert variant="warning">
                        Pas d'abonnement actif pour analyse.<br />
                        Pour renouveler, veuillez contacter notre équipe commerciale à <Button as="a" bsPrefix={'no-btn'} onClick={() => { openWidgetContactForm() }}>
                            l'aide du formulaire de contact</Button>
                    </Alert>
                    : selectedClients?.length > 0 ?
                <>
                    <Row className="row-allcenter">
                        <ActivitiesNumbers clientsUniqueId={selectedClients} setIsFetching={setIsFetchingNumbers} isFetching={isFetchingNumbers} oneMonth={oneMonth} fromStatsPage={true} />
                    </Row>
                    <Row className="row-allcenter video-graphs">
                        <VideoPlayGraph clientsUniqueId={selectedClients} setIsFetching={setIsFetching} isFetching={isFetching} oneMonth={oneMonth} fromStatsPage={true} />
                    </Row>
                </>
                : <Alert variant="info">Merci de sélectionner au moins un établissement</Alert>
            }
        </Container>
        : <Loader />
    )
}

export default StatPage;