import React, { useState, useEffect } from 'react';
import { Badge, Row, Col, Dropdown } from 'react-bootstrap';
import Loader from '../Loader.jsx';
import useDebounceSearch from "../../../hooks/useDebounchSearch.jsx";
import "./CustomSelectSingleComponent.scss";
import HelperMetiers360 from '../../../services/HelpersMetiers360.js';

/**
 * Select Single Item Code Component
 *
 * @version 2.0.0
 * @param {string} nameSelect  name of the form control
 * @param {string} optionLabel - the attribute of the option object that you want to display as the option's label
 * @param {string} valueLabel  the attribute of the option object that you want to use as the option's value
 * @param {array} options  the list of options to display
 * @param {function} selectOptions  the function that will be used to select an option (usually a setState)
 * @param {array} selectedOptions  the list of selected options stored in the parents component (usually as a state)
 * @param {string} selectId - unique Id for the select in case we use this component more than once in the same component
 * @param {string} autocomplete  'on' or 'off'. Autocomplete can visually conflicts with the dropdown list
 * @param {boolean} mustRedirect  true if the select is meant to directly redirect to another page (creating an order for instance) - false if it's supposed to store a selected value

 */

const CustomSelectSingleComponent = (props) => {
    const { selectId = 'singleSelect', options = [], nameSelect = "clientSelect", selectedOptions, setSelectedOptions, optionLabel = "name",
        valueLabel = "uniqueId", autocomplete = 'off', mustRedirect = false, optionIndex = null } = props;

    const {searchInput, search} = useDebounceSearch({placeholder: 'Rechercher', delay: 400, autocomplete: autocomplete});

    const [listOptions, setListOptions] = useState([]);

    useEffect(() => { // check that potential previously selected options are still available in the provided list
        if (options?.length && selectedOptions?.length > 0) {
            const selectedOptionExists = options.some((option) => option[valueLabel] === selectedOptions[0]);
            if (!selectedOptionExists) {
                setSelectedOptions([]);
            }
        }
    }, [options]);

    useEffect(() => {
        selectOptions();
    }, [options, search]);

    const selectedTokens = options ? 
        options
            .filter(op => selectedOptions.includes(op[valueLabel]))
            .map(op => 
                <Col key={`token${op[valueLabel]}`} className='selectedBadge'>
                    <Badge variant="primary" size="sm" 
                    className='p-2 item-badge'
                    onClick={()=> setSelectedOptions((prev)=> prev.filter(x=> options.includes(op[valueLabel]) ? true : x !== op[valueLabel]))}>
                        {optionIndex && (op[optionIndex] + " - ")}{op[optionLabel]}
                    </Badge>
                </Col>
            )
        :null;

    const selectOptions = () => {
        if (options) {
            setListOptions(options
            .sort((a, b) => a[optionLabel].localeCompare(b[optionLabel]))
                .filter(option => HelperMetiers360.isSearchInText(search, option[optionLabel])
                    || optionIndex && HelperMetiers360.isSearchInText(search, option[optionIndex])
                ));
        } else {
            setListOptions([]);
        }
    };

    const handleOptionClick = (value) => {
        if (selectedOptions?.includes(value)){ 
            setSelectedOptions([]);
        } else {
        setSelectedOptions([value]);
        }
    };

    const OptionsSelector = <Row className='form-client' style={{width: "100%"}}>
        {options ? <div className='select-single-item-widget'>
            <Dropdown style={{width: "80%"}}>
                {!mustRedirect && selectedTokens}
                <Dropdown.Toggle as="div" className="search-single-item">
                    {searchInput}
                </Dropdown.Toggle>
                <Dropdown.Menu
                className="result-list-single"
                as="div"
                flip={false}
                >
                    {listOptions?.length ? 
                    listOptions?.map((option, index) => (
                        <Dropdown.Item 
                        key={`${index}${option[optionLabel]}`}
                        onClick={() => {handleOptionClick(option[valueLabel]);}}
                        className={selectedOptions.includes(option[valueLabel]) ? 'selectedLine' : ''}
                        >
                            {optionIndex && (option[optionIndex] + " - ")}{option[optionLabel]}
                        </Dropdown.Item>
                    ))
                    : <Dropdown.Item>Aucun résultat ne correspond</Dropdown.Item> }
                </Dropdown.Menu>
            </Dropdown>

            {/* Generate a fantom input with the selected value for the formData to work */}
            <input type="hidden" value={selectedOptions[0] ?? ''} name={nameSelect} id={selectId + "input"} />
        </div>
        :<Loader />}
        </Row>;

    return (
        <>
            {OptionsSelector}
        </>  
    );
}


export default CustomSelectSingleComponent