import React, { useRef } from 'react';
import Plot from 'react-plotly.js';
import useCheckDataProps from '../../hooks/useCheckDataProps';
import { Alert } from 'react-bootstrap';
import Plotly from 'plotly.js/dist/plotly'
import PropTypes from 'prop-types';

const ClientCategories = ({ data = [], infoDisplayed, width = '1000px', height = '1000px' }) => {

    const [proxiedData, isValid] = useCheckDataProps(data || []);

    const categories = proxiedData.map((item) => item.categories);
    const values = proxiedData.map((item) => item[infoDisplayed]);
    const totalValue = values.reduce((acc, curr) => acc + curr, 0);

    const graphRef = useRef(null);

    const handleLegendClick = (event) => {
        let hiddenCategories = event?.layout?.hiddenlabels ? [...event.layout.hiddenlabels] : [];
        if (!hiddenCategories.includes(event?.label)) {
            hiddenCategories?.push(event?.label);
        } else {
            hiddenCategories = hiddenCategories?.filter(item => item !== event?.label);
        }
        const visibleCategories = categories?.filter(c => !hiddenCategories.includes(c));
        const newTotal = proxiedData?.filter(item => visibleCategories?.includes(item.categories))?.map((item) => item[infoDisplayed])?.reduce((acc, curr) => acc + curr, 0);
        if (graphRef.current?.el) {
            Plotly.relayout(graphRef.current.el, {
                'annotations[0].text': `${newTotal}`,
            });
        }
    }

    return data?.length > 0 ?
        isValid ?
            <div>
                <div style={{ width, height, zIndex: "1", minHeight: '30em' }}>
                    <Plot
                        ref={graphRef}
                        data={[
                            {
                                values: values,
                                labels: categories,
                                type: 'pie',
                                hole: 0.4,
                                textinfo: 'none',
                                hoverinfo: 'label+value+percent',
                                hoverlabel: {
                                    bgcolor: 'white',
                                    bordercolor: 'black',
                                },
                            }
                        ]}
                        style={{ width, height }}
                        layout={{
                            annotations: [
                                {
                                    font: {
                                        size: 20,
                                        color: '#000',
                                    },
                                    showarrow: false,
                                    text: `${totalValue}`,
                                    x: 0.5,
                                    y: 0.5,
                                },
                            ],
                        }}
                        onLegendClick={(event) => handleLegendClick(event)}
                    />
                </div>
            </div>
            : <Alert variant="danger">Les données ne correspondent pas</Alert>
        : <Alert variant="warning">Aucune donnée à afficher</Alert>;
}

ClientCategories.propTypes = {
    data: PropTypes.array.isRequired,
    infoDisplayed: PropTypes.string.isRequired,
    width: PropTypes.string,
    height: PropTypes.string,
};

export default ClientCategories;