/* eslint-disable no-multi-str */
import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import {Form, Col, Alert, Image, Row} from 'react-bootstrap';
import { Editor } from '@tinymce/tinymce-react';
import ButtonLoaderComponent from '../widgets/ButtonLoaderComponent.jsx';
import SelectMultipleClientsComponent from '../widgets/selectClient/SelectMultipleClientsComponent.jsx';
import { useStoreState } from 'easy-peasy';
import Jumbotron from '../widgets/jumbotron/Jumbotron.jsx';
import SelectMediaModuleComponent from '../widgets/SelectMediaModule/SelectMediaModuleComponent.jsx';
import useCustomGetStoreState from '../../hooks/useCustomGetStoreState.jsx';
import Loader from '../widgets/Loader.jsx';
import HelperMetiers360 from '../../services/HelpersMetiers360.js';

import DatePicker, { registerLocale } from "react-datepicker";
import { fr } from 'date-fns/locale/fr';

import "react-datepicker/dist/react-datepicker.css";

registerLocale('fr', fr);

const UploadSequenceComponent = (props) => {
	let navigate = useNavigate();

    const {apiData: {ACTIONS, BACKOFFICE_DEFAULT_VALUES}} = useStoreState(state => state.actionSlugs);

	const { fileContraints: fileContraints } = BACKOFFICE_DEFAULT_VALUES;
    const {allStories, isUpdateNecessary} = useCustomGetStoreState("stories")
    const {postSequence, updateSequence, sequence, action, readOne: readOneSeaquenceSlug} = props;

    const [contentDescription, setContentDescription] = useState(sequence ? sequence.description : '');
    const fileInput = useRef(null);
    const [fileInputName, setFileInputName] = useState('Sélectionnez un fichier');
    const [formErrors, setFormErrors] = useState({});
    const selectedStoriesIds = useRef(sequence && sequence.stories && sequence.stories.length ? allStories.filter(story => sequence.stories.includes(story.uniqueId)) : []);
    
    const [selectedClients, setSelectedClients] = useState(sequence && sequence.producers ? sequence.producers.map(producer => producer.id).map(String) : []);

    const [isSending, setIsSending] = useState(false);    

    const [releaseDate, setReleaseDate] = useState(sequence?.releaseDate ? new Date(sequence.releaseDate) : null);


    const handleEditorChange = (content, editor) => {
        setContentDescription(content);
    }

	const token = ACTIONS['sequences'][action].csrfToken;

    useEffect(() => {
        selectedStoriesIds.current = sequence && sequence.stories && sequence.stories.length ? allStories.filter(story => sequence.stories.includes(story.uniqueId)) : [];

    }, [allStories])
    
    const checkData = (e) => {
        e.preventDefault();
        const formData = new FormData(document.getElementById('SequenceForm'));
        const name = formData.get('sequence_upload[name]');
        const thumbnail = formData.get('sequence_upload[thumbnail]');
        let errorsList = {};

        if (!name) {
            errorsList.name = 'Veuillez saisir un nom pour ce parcours';
        }

        if ((!sequence && !thumbnail.size) || (sequence && !sequence.links.thumbnail && !thumbnail.size)  ) {
            errorsList.thumbnail='Sélectionnez une vignette';
        }

        if (thumbnail.size ) {
            if (thumbnail.size > fileContraints.MAX_SIZE_IMAGE_BYTES ) {
                errorsList['thumbnail'] = "Vignette trop volumineuse";
            }
            if (!fileContraints.IMAGE_MIME_TYPES.includes(thumbnail.type) ) {
                errorsList['thumbnail'] = "Format de la vignette non autorisé";
            }
        }

        if (!releaseDate) {
            errorsList.releaseDate='Sélectionnez une date de publication';
        }

        if (Object.keys(errorsList).length) {
            setFormErrors(errorsList);
            window.scrollTo(0, 0);

        }
        else {
            setIsSending(true);
            setFormErrors({});
            submitForm();
        }
    }

    const submitForm = () => {
        const formData = new FormData(document.getElementById('SequenceForm'));
        formData.append('sequence_upload[description]', contentDescription);
        formData.append('storiesId', selectedStoriesIds.current.map((story) => story.uniqueId));
        formData.append(`sequence_upload[releaseDate]`, HelperMetiers360.formatDateForFormControl(releaseDate));

        action === 'create'
				? postSequence({formData})
					.then((result) => {
						const { uniqueId } = result;
						navigate(
                            readOneSeaquenceSlug.replace(':uniqueId', uniqueId),
                            {
                                replace: true,
                                state: {fromEdit: true}
                            }
                            );
					})
				: updateSequence({formData, uniqueId: sequence.uniqueId})
					.then(() => {
						navigate(
                            readOneSeaquenceSlug.replace(':uniqueId', sequence.uniqueId),
                            {
                                replace: true,
                                state: {fromEdit: true}
                            }
                        );
					})
    }

    return (
        <div className='sequence-module-form'>
            {
                formErrors.global ?
						
							<Alert variant="danger">
								{formErrors.global}
							</Alert> : null
				}

            <Form id="SequenceForm" method="post" encType="multipart/form-data">
                <Form.Group>
                    <Form.Label>Nom</Form.Label>
                        <Form.Control 
                            placeholder="Exemple: Qu'est-ce que l'entreprise ?" 
                            name="sequence_upload[name]" 
                            defaultValue={sequence ? sequence.name : ''}
                            isInvalid={formErrors.name !== undefined}
                            />
                            <Form.Control.Feedback type='invalid'>{formErrors.name}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
					<Form.Label>Position</Form.Label>
						<Form.Control
                                id="sequence_upload_position"
                                name="sequence_upload[position]"
                                type="number"
                                defaultValue={sequence ? sequence.position : null}
                                isInvalid={formErrors.position !== undefined}
                            />
                            <Form.Control.Feedback type='invalid'>{formErrors.position}</Form.Control.Feedback>
				</Form.Group>
                <Form.Group>
					<Form.Label>Publication</Form.Label>
                    <DatePicker
                        showIcon
                        locale='fr'
                        dateFormat="dd/MM/yyyy"
                        selected={releaseDate}
                        onChange={(date) => {
                                setFormErrors((prev) => ({...prev, releaseDate: null}));
                                setReleaseDate(date);
                            }
                        } 
                        placeholderText="jj/mm/aaaa"
                    />
					{formErrors?.releaseDate && <div className='invalid-feedback' style={{display: "block"}}>Veuillez indiquer une date de publication</div>}
                </Form.Group>
                <Form.Group>
                    <Form.Label>Vignette ({fileContraints.IMAGE_MIME_TYPES_NAMES?.join(',')})  ({fileContraints.MAX_SIZE_IMAGE_MO} Mo max.)</Form.Label>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="inputGroupFileAddon01">
                                    {sequence && sequence.links.thumbnail ? 
                                        <Image src={sequence.links.thumbnail[128]} rounded thumbnail className={"max-width-200"} />
                                        :'Choisir la vignette'}
                                </span>
                            </div>
                            <div className="custom-file">
                                <input
                                    type="file"
                                    name="sequence_upload[thumbnail]"
                                    className={"custom-file-input"+(formErrors.thumbnail ? " is-invalid":null)}
                                    id="inputGroupFile01"
                                    aria-describedby="inputGroupFileAddon01"
                                    accept={fileContraints.IMAGE_MIME_TYPES?.join(',')}
                                    ref={fileInput}
                                    onChange={() => setFileInputName(fileInput.current.files[0].name)}
                                    // isInvalid={formErrors.thumbnail != undefined}
                                    />
                                <label className="custom-file-label" htmlFor="inputGroupFile01" data-browse="Parcourir">
                                    {fileInputName}
                                </label>
                            <Form.Control.Feedback type='invalid'>{formErrors.thumbnail}</Form.Control.Feedback>
                            </div>
                        </div>
                        <Form.Control
                            type="hidden"
                            isInvalid={formErrors.thumbnail !== undefined}
                        />
                        <Form.Control.Feedback type='invalid'>{formErrors.thumbnail}</Form.Control.Feedback>
                </Form.Group>
                <Row>
                    <Col className="mt-2"><Form.Check type="checkbox" name="sequence_upload[featured]" defaultChecked={sequence && sequence.featured} label="Ce parcours est mis en avant" /></Col>
                    <Col className="mt-2"><Form.Check type="checkbox" name="sequence_upload[private]" defaultChecked={sequence && sequence.private} label="Ce parcours est privé" /></Col>
                    <Col className="mt-2"><Form.Check type="checkbox" name="sequence_upload[freemium]" defaultChecked={sequence && sequence.freemium} label="Ce parcours est freemium" /></Col>
                </Row>
         <hr />
                <Form.Group>
                    <Form.Label>Description</Form.Label>
                    <Editor
                        name="sequence_upload[description]"
                        apiKey="t5rspxvw6u2zr48fduj1kf3twvxk7dsncf5bk8h50v07s8lg"
                        init={{
                            height: 500,
                            menubar: false,
                            plugins: [
                                'lists', 'charmap',
                                'help', 'wordcount', 'emoticons'
                            ],
                            toolbar:
                                'undo redo | bold italic | \
                                bullist numlist outdent indent | charmap emoticons | removeformat | help | wordcount'
                        }}
                        onEditorChange={handleEditorChange}
                        initialValue={sequence ? sequence.description : null} />
                </Form.Group>

                <Jumbotron>
                   {!isUpdateNecessary ? <SelectMediaModuleComponent 
                        mediaResultRef={selectedStoriesIds}
                        allMedias={allStories}
                        mediaType={"story"}
                        /> : <Loader/> }
                </Jumbotron>

                {sequence ? 
                <Form.Group >
                    <Form.Control
                        name="uniqueId"
                        defaultValue={sequence.uniqueId}
                        type="hidden"
                    />
                </Form.Group>:null}

                <Form.Group >
                    <Form.Control
                        name="sequence_upload[_token]"
                        defaultValue={token}
                        type="hidden"
                    />
                </Form.Group>
                <Row>
                <Col>
                    <Form.Label><h3>Producteur(s)</h3></Form.Label>
                    <SelectMultipleClientsComponent
                        nameSelect='sequence_upload[producers][]'
                        selectedClients = {selectedClients ? selectedClients:[]}
                        setSelectedClients = {setSelectedClients} />
                </Col>
                </Row>
                <ButtonLoaderComponent 
                    onClick={checkData} 
                    msg={sequence ? "Éditer": "Sauvegarder" }
                    isSending={isSending}
                    active={!isSending}
                    size="lg"
                    className="mt-2"
                    disabled={!(selectedStoriesIds && selectedStoriesIds.length !== 0) || isSending} />

            </Form>
        </div>
    );
}

export default UploadSequenceComponent;