import React, { useState, useEffect, useRef } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Row, Col, Card, ListGroup, Alert, Container, Badge, Form, Button, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { Link } from "react-router-dom";

import HelperMetiers360 from '../../services/HelpersMetiers360.js';

import CardGroupMediaComponent from '../widgets/CardGroupMedia/CardGroupMediaComponent.jsx';
import SelectMediaModuleComponent from '../widgets/SelectMediaModule/SelectMediaModuleComponent.jsx';
import { useModal } from '../../hooks/useModal.jsx';
import useCustomGetStoreState from '../../hooks/useCustomGetStoreState.jsx';
import './PlaylistDetailsComponent.scss';
import { useToast } from '../../hooks/useToast.jsx';

const PlaylistDetailsComponent = (props) => {
    const { playlist } = props;

    const { modalComponent, setModalShow, modalData, setModalData, setIsSending } = useModal();
    const toast = useToast();

    const {allVideos, isFetchingAllVideos} = useCustomGetStoreState("videos");

    const {allQuizzes, isFetchingAllQuizzes} = useCustomGetStoreState("quizzes");

    const {allHeadsets} = useCustomGetStoreState("headsets")
    const { updatePlaylist } = useStoreActions((actions => actions.playlists));

    const [editTitle,setEditTitle] = useState(false);
    const [isThereContent, setIsThereContent] = useState();
    const [clientHeadsets, setClientHeadsets] = useState([]);
    const [clientHeadsetsPlaylist, setClientHeadsetsPlaylist] = useState([]);
    const nameRef = useRef(null);
    const [isPlaylistInGroup, setIsPlaylistInGroup] = useState(playlist.isInGroup ?? false);

    const [realHeadsetsSelected, setRealHeadsetsSelected] = useState([]);
    const [sendSelectedHeadsets, setSendSelectedHeadsets] = useState(false);
    const [realVideosSelected, setRealVideosSelected] = useState([]);
    const [sendSelectedVideos, setSendSelectedVideos] = useState(false);
    const [realQuizzesSelected, setRealQuizzesSelected] = useState([]);
    const [sendSelectedQuizzes, setSendSelectedQuizzes] = useState(false);

    const sendForm = (isInGroupValue = null) => {
        setIsSending(true);
        editTitle && setEditTitle(false);
        const formData = new FormData();
        formData.append('playlist_videos', JSON.stringify(videoResultRef.current.map((video) => video.uniqueId)));
        formData.append('playlist_quizzes', JSON.stringify(quizResultRef.current.map((quiz) => quiz.uniqueId)));
        formData.append('playlist_headsets', JSON.stringify(headsetResultRef.current.map((headset) => headset.uniqueId)));
        nameRef && nameRef.current && nameRef.current.value && formData.append('playlist_name', nameRef.current.value);
        isInGroupValue !== null && formData.append('playlist_is_in_group', isInGroupValue);
        updatePlaylist({formData, uniqueId: playlist.uniqueId})
            .then(() => {
                toast.success()
                setModalShow(false);
            })
            .finally(() => setIsSending(false));
    };

    // client
    const readOneClient = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('clients'));
    const readOneClientSlug = readOneClient && readOneClient.readOne ? 
    <Link to={readOneClient.readOne.replace(':uniqueId', playlist.client.uniqueId)}>{playlist.client.name}</Link>
    : <>{playlist.client.name}</>

   

    const headsetResultRef = useRef(playlist && playlist.headsets ? clientHeadsets.filter(headset => playlist.headsets.includes(headset.uniqueId)) : []);
    useEffect(() => {
        if(clientHeadsets.length > 0){
            setClientHeadsetsPlaylist(clientHeadsets.filter((headset) => !headset.playlistUniqueId));
            headsetResultRef.current = playlist && playlist.headsets ? clientHeadsets.filter(headset => playlist.headsets.includes(headset.uniqueId)) : [];
        }
    }, [playlist, clientHeadsets]);


    useEffect(() => {
        if(allHeadsets?.length > 0 && allHeadsets[0].hasOwnProperty("clientUniqueId")){
            setClientHeadsets(allHeadsets.filter((headset) => headset.clientUniqueId === playlist.client.uniqueId));
        };
    }, [allHeadsets]);

    const headsetList = (playlist.headsets && playlist.headsets.length > 0)
        && clientHeadsets
            .filter(headset => playlist.headsets.includes(headset.uniqueId))
            .sort((a, b) => a.deviceId.localeCompare(b.deviceId))
            .map((headset) => 
                <ListGroup.Item key={headset.uniqueId}>
                    {headset.headsetName} {headset.headsetName !== null && ' - '} 
                    {!headset.headsetName?.includes(headset.deviceId) && <i>{headset.deviceId}</i>}
                </ListGroup.Item>);

    useEffect(() => {
        if(sendSelectedHeadsets) {
            headsetResultRef.current = realHeadsetsSelected;
            sendForm();
            setSendSelectedHeadsets(false);
        }
    }, [sendSelectedHeadsets])

    const selectHeadsets = () => {
        setModalData({
            ...modalData,
            header:"Edition de l'affectation des casques",
            content:<SelectMediaModuleComponent draggable={false} allMedias={clientHeadsetsPlaylist} 
                mediaResultRef={headsetResultRef} mediaType={"headset"} setRealValue={setRealHeadsetsSelected} /> ,
            size: 'xl',
            cancelButton: 'Annuler',
            onValidate: () => setSendSelectedHeadsets(true)
        });
        setModalShow(true);
    }

    // list videos
    const videoResultRef = useRef(playlist && playlist.videos ? allVideos.filter(video => playlist.videos.includes(video.uniqueId)) : []);
    useEffect(() => {
        videoResultRef.current = playlist && playlist.videos ? allVideos.filter(video => playlist.videos.includes(video.uniqueId)) : [];
    }, [playlist, allVideos]);

    useEffect(() => {
        setIsThereContent((playlist.videos && playlist.videos.length > 0) || (playlist.quizzes && playlist.quizzes.length > 0));                                              
    }, [playlist]);

    const {readOne:readOneVideo} = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('videos'));
    const videoList = (playlist.videos && playlist.videos.length > 0) ?
        allVideos
                .filter(video => playlist.videos.includes(video.uniqueId))
                .sort((a, b) => a.videoName.localeCompare(b.videoName))
        :null;
    const videoListDisplay =videoList ?
        <CardGroupMediaComponent mediaType="video" mediaIsLoading={isFetchingAllVideos} mediaList={videoList} readOne={readOneVideo} />
        : <Alert className="mx-3" variant="danger">Pas d'expérience immersive enregistrée.</Alert>;

    useEffect(() => {
        if(sendSelectedVideos) {
            videoResultRef.current = realVideosSelected;
            sendForm();
            setSendSelectedVideos(false);
        }
    }, [sendSelectedVideos])

    const selectVideos = () => {
        setModalData({
            ...modalData,
            header: "Edition des expériences immersives",
            content: <SelectMediaModuleComponent allMedias={allVideos} mediaResultRef={videoResultRef} mediaType={"video"}
                setRealValue={setRealVideosSelected} /> ,
            size: 'xl',
            cancelButton: 'Annuler',
            onValidate: () => setSendSelectedVideos(true)
        });
        setModalShow(true);
    }

    // list quizzes
    const quizResultRef = useRef(playlist && playlist.quizzes ? allQuizzes.filter(quiz => playlist.quizzes.includes(quiz.uniqueId)) : []);
    useEffect(() => {
        quizResultRef.current = playlist && playlist.quizzes ? allQuizzes.filter(quiz => playlist.quizzes.includes(quiz.uniqueId)) : [];
    }, [playlist, allQuizzes]);

    const {readOne:readOneQuiz} = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('quizzes'));
    const QuizList = (playlist.quizzes && playlist.quizzes.length > 0) ?
            allQuizzes
                .filter(quiz => playlist.quizzes.includes(quiz.uniqueId))
                .sort((a, b) => a.name.localeCompare(b.name))
            :null;
    const quizListDisplay = QuizList ?
        <CardGroupMediaComponent mediaType="quiz"  mediaIsLoading={isFetchingAllQuizzes} mediaList={QuizList} readOne={readOneQuiz} />
        : <Alert className="mx-3" variant="danger">Pas de quiz enregistré.</Alert>
    
    useEffect(() => {
        if(sendSelectedQuizzes) {
            quizResultRef.current = realQuizzesSelected;
            sendForm();
            setSendSelectedQuizzes(false);
        }
    }, [sendSelectedQuizzes])

    const selectQuizzes = () => {
        setModalData({
            ...modalData,
            header:"Edition des Quiz",
            content: <SelectMediaModuleComponent allMedias={allQuizzes} mediaResultRef={quizResultRef} mediaType={"quiz"}
                setRealValue={setRealQuizzesSelected}/>,
            size: 'xl',
            cancelButton: 'Annuler',
            onValidate: () => setSendSelectedQuizzes(true)
        });
        setModalShow(true);
    }

    const toggleInGroup = (isInGroupValue) => {
        setIsPlaylistInGroup(isInGroupValue);
        sendForm(isInGroupValue);
    }

    const alertHeadset = 
    <>{(headsetResultRef.current.length > 0 && (videoResultRef.current.length === 0 && quizResultRef.current.length === 0)) ? 
        <Alert variant="danger">Vous avez sélectionnez le(s) casque(s) :<><br/></>{headsetResultRef.current.map((headset) => headset.headsetName)?.join(', ')}
        <br />
                <strong>Vous devez sélectionnez au moins une expérience immersive et/ou un quiz pour valider la création de la playlist.</strong></Alert>
        : null
    }
    </>

    return (
        <>
            <Container className="playlist_detail">
            {alertHeadset}
                <Row>
                    <Col>
                        <Card>
                            <Card.Header className="d-flex align-items-center justify-content-between">
                                {editTitle ? 
                                    <>
                                    <Form.Control ref={nameRef} type="input" name='playlist_name' defaultValue={playlist.name} onKeyUp={(e) =>{if(e.code === 'Enter') sendForm();}} />
                                    <Badge className="m-2 fs-6" onClick={() => setEditTitle(false)}><i className="icon-x-M360"></i></Badge>
                                    <Badge className="m-2 fs-6" bg="success" onClick={sendForm}><i className="icon-check-M360"></i></Badge>
                                    </>
                                    :<h3>{playlist.name}</h3>}
                                {playlist.isEditable && !editTitle ? 
                                    <Button variant="secondary" size="sm" className="m-2" onClick={() => {setEditTitle(true)}}><i className="fas fa-edit"/> Éditer</Button>
                                    :null}
                            </Card.Header>
                            <Card.Body>
                                <ListGroup variant="flush">
                                    <Row>
                                        <Col>
                                        { playlist.createdAt ?
                                            <ListGroup.Item>
                                                <strong>Publication :</strong> {HelperMetiers360.getdisplayDateType(playlist.createdAt, 'day')}
                                            </ListGroup.Item>
                                            :null}
                                        { playlist.updatedAt ?
                                            <ListGroup.Item>
                                                <strong>Mise à jour :</strong> {HelperMetiers360.getdisplayDateType(playlist.updatedAt, 'day')}
                                            </ListGroup.Item>
                                            :null}
                                        </Col>
                                        <Col>
                                        { playlist.owner ?
                                            <ListGroup.Item>
                                                <strong>Créée par :</strong> {playlist.owner.username}
                                            </ListGroup.Item>
                                            :null}
                                        
                                            <ListGroup.Item>
                                                <strong>Client :</strong> {readOneClientSlug}
                                            </ListGroup.Item>
                                        </Col>
                                    </Row>
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <strong>
                                                    {(playlist.headsets && playlist.headsets.length > 0) ?
                                                        <>Casques ayant cette playlist : </> 
                                                        :<>Pas de casques ayant cette playlist</>
                                                    }
                                               </strong>
                                            {headsetList}
                                            {playlist.isEditable &&  
                                                <>
                                                    <Col className="d-flex">
                                                        {playlist.headsets && playlist.headsets.length > 0 ? (
                                                        <Button variant="secondary" className="my-2" onClick={selectHeadsets}>
                                                            <i className="fas fa-edit" /> &nbsp;Gérer les casques
                                                        </Button>
                                                        ) 
                                                        : isThereContent ? 
                                                        (<Button variant="success" className="my-2" onClick={selectHeadsets}>
                                                            <i className="fas fa-plus" /> &nbsp;Affecter à des casques
                                                        </Button>) 
                                                        : <Alert variant="info">Veuillez ajouter au moins un contenu à cette playlist pour pouvoir l'affecter à un casque</Alert>}
                                                    </Col>
                                                </>
                                            }
                                            </Col>
                                            <Col>
                                                <ListGroup.Item>
                                                    <strong>Playlist exclusive * :</strong>
                                                     <ToggleButtonGroup type="radio" name="playlist_is_in_group" onChange={toggleInGroup} 
                                                        value={isPlaylistInGroup} className='ms-2'>
                                                        <ToggleButton value={false} disabled={!playlist.isEditable} checked={!isPlaylistInGroup}
                                                            key="playlist_not_in_group" id="playlist_not_in_group">Oui</ToggleButton>
                                                        <ToggleButton value={true} disabled={!playlist.isEditable} checked={!!isPlaylistInGroup}
                                                            key="playlist_in_group" id="playlist_in_group">Non</ToggleButton>
                                                    </ToggleButtonGroup>
                                                </ListGroup.Item>
                                                <i>
                                                    * Oui : Les casques concernés n'auront que votre sélection.<br/>
                                                    Non : Votre sélection apparaîtra dans un espace dédié en conservant le reste du catalogue.</i>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row> 
                    <Col className='playlist_select_items'>
                        <Card>
                            <Card.Header>
                                <h3>{videoList?.length ?? 0} expériences immersives</h3>
                                {playlist.isEditable && !isFetchingAllVideos ? 
                                    <Button variant="secondary" size="sm" onClick={selectVideos} className="btn-sm ml-auto"><i className="fas fa-edit"/> Éditer</Button>
                                    :null}
                            </Card.Header>
                            {videoListDisplay}
                        </Card>
                    </Col>
                    <Col className='playlist_select_items'>
                        <Card>
                            <Card.Header>
                                <h3>{QuizList?.length ?? 0} quiz</h3>
                                {playlist.isEditable  && !isFetchingAllQuizzes ? 
                                    <Button variant="secondary" size="sm" onClick={selectQuizzes} className="btn-sm ml-auto"><i className="fas fa-edit"/> Éditer</Button>
                                    :null
                                }
                            </Card.Header>
                            {quizListDisplay}
                        </Card>
                    </Col>

                </Row>
            </Container>
            <>{modalComponent}</>
        </>
    );
}

export default PlaylistDetailsComponent;