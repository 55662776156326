import React, { useRef, useMemo } from 'react';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import regions from './geoJson/regions.json';
import departments from './geoJson/departements.json';
import departments_numbers from './geoJson/departments_numbers.json';
import { Row, Alert } from 'react-bootstrap';
import useCheckDataProps from '../../hooks/useCheckDataProps';
import PropTypes from 'prop-types';
import './GeoMap.scss';

const GeoMap = ({ data = [], infoDisplayed, mapType, width = "100%", height = "1000px" }) => {

    const [proxiedData, isValid] = useCheckDataProps(data || []);
    let geojsonRef = useRef();

    const integrateDataWithGeoJSON = (mapType) => {
        const mapTypeUsed = mapType === "regions" ? regions : departments;
        return {
            ...mapTypeUsed,
            features: mapTypeUsed.features.map(feature => {
                const zoneData = proxiedData.find(d =>
                    d.zone === feature.properties.nom
                    || departments_numbers?.find(dep => dep.department_number == d.zone)?.department_name == feature.properties.nom
                );
                return {
                    ...feature,
                    properties: {
                        ...feature.properties,
                        metricValue: zoneData ? zoneData[infoDisplayed] : 0
                    }
                };
            })
        };
    }

    const geoDataRegions = useMemo(() => integrateDataWithGeoJSON("regions"), [data, infoDisplayed, mapType]);
    const geoDataDepartments = useMemo(() => integrateDataWithGeoJSON("departments"), [data, infoDisplayed, mapType]);
    const geoData = mapType === "regions" ? geoDataRegions : geoDataDepartments;

    const colorsForInfo = {
        color1: '#800026',
        color2: '#BD0026',
        color3: '#E31A1C',
        color4: '#FC4E2A',
        color5: '#FD8D3C',
        color6: '#FFEDA0',
    };

    const metricValues = useMemo(() =>
        geoData?.features?.map(feature => feature?.properties?.metricValue)
        , [geoData, infoDisplayed]);

    const maxValue = useMemo(() => {
        return geoData?.features?.length
            ? Math.max(...metricValues)
            : 0;
    }, [geoData, infoDisplayed]);

    const averageValue = useMemo(() => {
        if (geoData?.features?.length) {
            const average = metricValues?.reduce((sum, value) => sum + value, 0) / metricValues?.length;
            return Math.ceil(average);
        } else {
            return 0;
        }
    }, [geoData, infoDisplayed]);

    const getColor = (value) => {
        return value > averageValue ? colorsForInfo?.color1 :
            value > Math.ceil(averageValue / 2) ? colorsForInfo?.color2 :
                value > Math.ceil(averageValue / 3) ? colorsForInfo?.color3 :
                    value > Math.ceil(averageValue / 4) ? colorsForInfo?.color4 :
                        value > Math.ceil(averageValue / 5) ? colorsForInfo?.color5 :
                            colorsForInfo?.color6;
    }

    const style = (feature) => ({
        fillColor: getColor(feature.properties.metricValue),
        weight: 2,
        opacity: 1,
        color: 'white',
        dashArray: '3',
        fillOpacity: 0.7
    });


    const onEachFeature = (feature, layer) => {
        layer.on({
            mouseover: (e) => highlightFeature(e),
            mouseout: (e) => resetHighlight(e),
        });

        if (feature?.properties?.nom && feature?.properties?.metricValue !== undefined) {
            layer.bindTooltip(
                `${feature.properties.nom}: ${feature.properties.metricValue}`,
                {
                    permanent: false,
                    direction: "auto",
                    className: "layer-tooltip"
                }
            );
        }
    };

    const highlightFeature = (e) => {
        const layer = e.target;

        layer.setStyle({
            weight: 7,
            color: 'white',
            dashArray: '',
        });
    };

    const resetHighlight = (e) => {
        geojsonRef.current.resetStyle(e.target);
    };

    return data.length > 0 && geoData ?
        isValid ?
            <div className='mt-3'>
                <Row>
                    <div className="captions-color">
                        <div>
                            <div className='little-square' style={{ backgroundColor: colorsForInfo?.color6 }}></div>
                            <span className='caption-number'>0</span>
                        </div>
                        <div>
                            <div className='little-square' style={{ backgroundColor: colorsForInfo?.color5 }}></div>
                            <span className='caption-number'>{Math.ceil(averageValue / 5)}</span>
                        </div>
                        <div>
                            <div className='little-square' style={{ backgroundColor: colorsForInfo?.color4 }}></div>
                            <span className='caption-number'>{Math.ceil(averageValue / 4)}</span>
                        </div>
                        <div>
                            <div className='little-square' style={{ backgroundColor: colorsForInfo?.color3 }}></div>
                            <span className='caption-number'>{Math.ceil(averageValue / 3)}</span>
                        </div>
                        <div>
                            <div className='little-square' style={{ backgroundColor: colorsForInfo?.color2 }}></div>
                            <span className='caption-number'>{Math.ceil(averageValue / 2)}</span>
                        </div>
                        <div>
                            <div className='little-square' style={{ backgroundColor: colorsForInfo?.color1 }}></div>
                            <span className='caption-number'>{Math.ceil(averageValue / 1)}</span>
                        </div>
                        <div>
                            <div className='little-square'></div>
                            <span className='caption-number'>{maxValue}</span>
                        </div>
                    </div>
                </Row>
                <MapContainer center={[47, 1]} zoom={6} style={{ height, width, zIndex: "1", minHeight: '30em' }}>
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                    <GeoJSON
                        key={`key${infoDisplayed}-${mapType}`}
                        data={geoData}
                        style={style}
                        onEachFeature={onEachFeature}
                        ref={geojsonRef} />
                </MapContainer>
            </div>
            : <Alert variant="danger">Les données ne correspondent pas</Alert>
        : <Alert variant="warning">Aucune donnée à afficher</Alert>;
};

GeoMap.propTypes = {
    data: PropTypes.array.isRequired,
    infoDisplayed: PropTypes.string.isRequired,
    mapType: PropTypes.string.isRequired,
    width: PropTypes.string,
    height: PropTypes.string
}

export default GeoMap;
