import React, { useState, useEffect } from "react";
import { Row, Image, Button } from "react-bootstrap";

import DynamicTable from "../dynamicTable/DynamicTable";
import useDebounceSearch from "../../../hooks/useDebounchSearch";
import InfinitePaginationListComponent from "../InfinitePaginationList/InfinitePaginationListComponent";
import HelperMetiers360 from "../../../services/HelpersMetiers360.js";

const SelectItemComponent = (props) => {
    const { list, mediaSearchCallBack, searchTitle, onSelect, maxVisibleItem, displayType = "table", mediaType,
        onSelectAll = null, onlySummaryInfos = false } = props;

    const listToDisplay = list ?? [];

    const [filteredList, setFilteredList] = useState(listToDisplay.slice(0, maxVisibleItem));
    const { searchInput, search } = useDebounceSearch({ placeholder: searchTitle, delay: 400 });

    useEffect(() => {
        if (mediaSearchCallBack) {
            setFilteredList(
                [...mediaSearchCallBack(list, mediaType, search)]
            );
        } else {
            setFilteredList([
                ...list.filter((listItem) => HelperMetiers360.isSearchInText(search, listItem?.name))
            ]);
        }
    }, [search, list]);

    if (listToDisplay.length === 0) {
		return <></>;
	}

    const sortType = [
        {
            value : 'thumbnail', label : 'Vignette',
            test : listToDisplay[0].thumbnail !== undefined,
            method: null,
            display: (listItem) => <Image src={listItem.thumbnail} thumbnail />
        },
        {
            value : 'name', label : 'Nom',
            test : listToDisplay[0].name !== undefined,
            method: (a,b) => HelperMetiers360.localeCompareWithNullable(a.name, b.name),
            display: (listItem) => listItem.name
        }
    ];

    const displayOptions = {
        "table": <DynamicTable 
                    contentTable={filteredList.slice(0, maxVisibleItem)}
                    contentSort = {sortType}
                    valueInitSort = "name"
                    index = 'uniqueId'
                    handleClick={onSelect}
                    />,
        "cards": <InfinitePaginationListComponent
                    mediaType={mediaType}
                    mediaList={filteredList}
                    onClickItem={onSelect}
                    maxVisibleItem={maxVisibleItem}
            onlySummaryInfos={onlySummaryInfos}
                 />
    }

    const isViewFiltered = filteredList.length !== list.length;

    return <>
        <Row className="m-2">{list.length > 1 && searchInput}</Row>
        <Row className='d-flex justify-content-center my-2'>
            {onSelectAll && filteredList.length > 0 && <Button variant='success' className='w-auto' onClick={() => onSelectAll(isViewFiltered && filteredList)}>
                {isViewFiltered ? `Sélectionner les ${filteredList.length} éléments de la liste` : "Tout sélectionner"}
            </Button>}
        </Row>
        <Row>
            { displayOptions[displayType]}
        </Row>
    </>;
}

export default SelectItemComponent;