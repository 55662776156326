import React from "react";
import Plot from 'react-plotly.js';
import useCheckDataProps from '../../hooks/useCheckDataProps';
import { Alert } from 'react-bootstrap';
import HelperMetiers360 from "../../services/HelpersMetiers360";
import PropTypes from 'prop-types';

const ChronogramComponent = ({ data = [], aggregated, height = "100%" }) => {

    const [proxiedData, isValid] = useCheckDataProps(data || []);

    const getdisplayDate = (timestamp) => {
        const formatedDate = new Date(timestamp);
        return HelperMetiers360.getdisplayDateType(formatedDate, "day");
    }

    const generateGroupedData = (proxiedData, aggregated) => {
        if (aggregated) return null;

        const uniqueIdWeeks = [...new Set(proxiedData.map(item => item.id_week))].sort();
        const xAxis = uniqueIdWeeks.map(id_week => getdisplayDate(id_week));

        const groupedData = proxiedData.reduce((acc, item) => {
            if (!acc[item.video_id]) {
                acc[item.video_id] = { x: xAxis, y: Array(xAxis.length).fill(0), video_id: item.video_id, video_name: item.video_name };
            }
            const weekIndex = xAxis.indexOf(getdisplayDate(item.id_week));
            acc[item.video_id].y[weekIndex] = item.counts_views_week;
            return acc;
        }, {});

        return groupedData;
    };
    const groupedVideos = !aggregated && generateGroupedData(proxiedData, aggregated);

    const traces = !aggregated ?
        Object.values(groupedVideos).map(video => ({
            x: video.x,
            y: video.y,
            type: 'scatter',
            mode: 'lines',
            name: video.video_name,
        }))
        : [{
            x: proxiedData.map((data) => getdisplayDate(data.id_week)),
            y: proxiedData.map((data) => data.counts_views_week),
            type: 'scatter',
            mode: 'lines',
            marker: { color: 'red' },
        }];

    return data?.length > 0 ?
        (isValid ?
            <Plot
                data={traces}
                    layout={{
                        autosize: true,
                        yaxis: {
                            title: {
                                text: 'Nombre total de vues hebdomadaires',
                                font: {
                                    size: 14,
                                    color: '#7f7f7f',
                                },
                                standoff: 60,
                            },
                        },
                        margin: {
                            l: 50,
                            r: 50,
                            b: 100,
                            t: 50,
                            pad: 4,
                        },
                    }}
                    useResizeHandler={true}
                style={{ width: '100%', height, minHeight: '30em' }}
                />
            : <Alert variant="danger">Les données ne correspondent pas</Alert>)
        : <Alert variant="warning">Aucune donnée à afficher</Alert>;
}

ChronogramComponent.propTypes = {
    data: PropTypes.array.isRequired,
    aggregated: PropTypes.bool.isRequired,
    height: PropTypes.string,
}

export default ChronogramComponent;