import React, { useEffect, useMemo, useState } from "react";
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'easy-peasy';

import { Row, Button, Container, Tooltip, OverlayTrigger, Alert } from "react-bootstrap";

import AuthorizationChecker from "../../services/AuthorizationChecker.js";
import HelperMetiers360 from '../../services/HelpersMetiers360';
import Loader from "../../components/widgets/Loader.jsx";
import {useModal} from "../../hooks/useModal";

import useBackButton from "../../hooks/useBackButton.jsx";
import GroupDisplayContentComponent from "../../components/groups/GroupDisplayContentComponent.jsx";

import './GroupDetailPage.scss';
import DownloadGroupsVideosPDF from "../../components/widgets/generatePDFs/groupsVideosTable.jsx";
import useCustomGetStoreState from "../../hooks/useCustomGetStoreState.jsx";
import DownloadGroupThumbnailsContentPDF from "../../components/widgets/generatePDFs/groupThumbnailsContent.jsx";

const GroupDetailPage = (props) => {
    const {uniqueId} = useParams();
    const navigate = useNavigate();

    // Group state variables + actions
    const { groupById } = useStoreState(state => state.groups);
    const {fetchGroupById, deleteGroup} = useStoreActions(actions => actions.groups);
    const pedagogicModuleSlugs = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('pedagogicModules'));
    const clientsSlugs = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('clients'));

    const {allVideos} = useCustomGetStoreState("videos");
    const { allQuizzes } = useCustomGetStoreState("quizzes");
    const { allGroups } = useCustomGetStoreState("groups");

    // ActionSlug state computed variable
    const groupsSlugs = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('groups'));

    const [isLoading, setIsLoading] = useState(false);
    const [isMounted, setIsMounted] = useState(false);

    const [hasItemsContent, setHasItemsContent] = useState(0);
    const [generateSubGroupsPDF, setGenerateSubGroupsPDF] = useState(false);
    const [generateThumbnailContentGroupPDF, setGenerateThumbnailContentGroupPDF] = useState(false);

    const {modalComponent, setModalShow, setModalData, modalData} = useModal();

    useEffect(() => {
        setIsMounted(true);
        if (!isLoading) {
            setIsLoading(true);
            setGenerateSubGroupsPDF(false);
            setGenerateThumbnailContentGroupPDF(false);
            fetchGroupById(uniqueId)
                .finally(() => {
                    setIsLoading(false);
                });
        }

        return () => setIsMounted(false);
	}, [uniqueId]);

    // If current group contains videos, we want to display the current group as a column in pdf to display its videos
    const groupsToDisplay = useMemo(() => {
        if (groupById) {
            const groupSubGroups = groupById?.items?.filter(item => item.type === "group");
            const groupVideos = groupById?.items?.filter(item => item.type === "video");

            return groupVideos?.length > 0
                ? [
                    { name: groupById?.name, uniqueId: groupById?.uniqueId },
                    ...groupSubGroups.sort((a, b) => a.name.localeCompare(b.name))
                ]
                : groupSubGroups.sort((a, b) => a.name.localeCompare(b.name));
        } else {
            return [];
        }
    }, [groupById]);

    const { backButtonComponent } = useBackButton()

    // if group is in another group
    const IsInGroups = groupById?.groups?.length > 0;

    const createDeleteModal = () => {
        setModalData({
            ...modalData,
            header:<>Suppression d'un groupe</>,
            content: <h5>Etes-vous sûr⋅e de vouloir supprimer ce groupe ?</h5>,
            resetButton: 'Supprimer',
            cancelButton:'Annuler',
            onReset:() => {
                deleteGroup({uniqueId: uniqueId})
                    .then(() => {
                        redirect(groupsSlugs.readAll);
                    })
            },
        });
        setModalShow(true);
    };

    const openFileModal = () => {
        setModalData({
            ...modalData,
            header: <>{groupById.files.length > 1 ? 'Fichiers liés':'Fichier lié'}</>,
            content: <>{filesModalContent}</>,
        });
        setModalShow(true);
    };

    const redirect = (url) => {
        navigate(url);
    }

    const onDownloadFile = (url) => {
        window.open(url);
    }

    const footerFiles = groupById?.files?.length > 0 &&
        <p><strong>Fichiers : </strong> <Button className="filesButton" onClick={openFileModal}>{groupById.files.length}</Button></p>;

    const filesModalContent = groupById &&
            <div className="tableFiles">
                <table>
                <thead>
                    <tr>
                    <th className="name"></th>
                    <th className="downl"></th>
                    </tr>
                </thead>
                    <tbody>
                    {groupById.files.sort((a, b) => a.localName?.localeCompare(b.localName))
                        .map((file , index) => 
                        <tr key={`${file.localName}${index}`}>
                            <td>
                                {file.localName}
                            </td>
                            <td>
                                <Button variant="secondary" className="p-2 m-2" size="sm" onClick={()=>{onDownloadFile(file.url)}}> 
                                    <i className="fas fa-download"/>
                                </Button>
                            </td>
                        </tr>
                        )                
                    }
                    </tbody>
                </table>
            </div>;

    const getMediaReleaseDate = (dateGiven) => {
        return HelperMetiers360.getdisplayDateType(dateGiven, 'day');
    }

    const producersName = (producers) => producers.map((p,i) => 
        <span key={`link${p.id}`}>
            <a href="#" onClick={(e) => { e.preventDefault(); navigate(clientsSlugs.readOne.replace(':uniqueId', p.id));}}>
                {p.clientName}
            </a>
            {(i === producers.length -1) ? "" : "  -  "}
        </span>);

    const groupProducers = groupById?.producers?.length > 0 && <p><strong>Produit par :</strong> {producersName(groupById.producers)}</p>;
        
    const publicationDate = groupById?.releaseDate && <p><strong>Publication : </strong>{getMediaReleaseDate(groupById.releaseDate)}</p>;

    const updateDate = groupById?.updatedAt && <p><strong>Mise à jour : </strong>{getMediaReleaseDate(groupById.updatedAt)}</p>;

    const directAccessCode = groupById?.isDirectlyAccessible && groupById?.directAccessCode
        && <h6 className="my-4">Code d'accès direct : <span className='badge-code'>{groupById.directAccessCode}</span></h6>;

    const today = new Date();

    const isDateLaterThanToday = (date1) => {
        const date1Conv = new Date(date1);
        return !!(date1Conv > today);
    }

    const canDownloadContent = groupById?.idHeadset !== 0 && groupById?.items?.length !== 0;
    const canDownloadThumbnailsContent = groupById?.items?.length !== 0;

    // TODO : Remove this constant when back will return only content accessible by user connected
    const items = useMemo(() => {
        const items = [];

        if (groupById && (allGroups.length || allVideos.length || allQuizzes.length)) {
            allGroups.forEach(group => {
                const groupInSelectedGroup = groupById.items?.find(item => item.type === "group" && item.uniqueId === group.uniqueId);
                if (!!groupInSelectedGroup) {
                    items.push({
                        uniqueId: group.uniqueId,
                        displayOrder: groupInSelectedGroup ? groupInSelectedGroup.displayOrder : group.position,
                        thumbnail: group.links.thumbnail,
                        type: 'group',
                    });
                }
            });
            allVideos.forEach(video => {
                const videoInSelectedGroup = groupById.items?.find(item => item.type === "video" && item.uniqueId === video.uniqueId);
                if (!!videoInSelectedGroup) {
                    items.push({
                        uniqueId: video.uniqueId,
                        displayOrder: videoInSelectedGroup ? videoInSelectedGroup.displayOrder : video.position,
                        thumbnail: video.links.thumbnail,
                        type: 'video',
                    });
                }
            });
            allQuizzes.forEach(quiz => {
                const quizInSelectedGroup = groupById.items?.find(item => item.type === "quizz" && item.uniqueId === quiz.uniqueId);
                if (!!quizInSelectedGroup) {
                    items.push({
                        uniqueId: quiz.uniqueId,
                        displayOrder: quizInSelectedGroup ? quizInSelectedGroup.displayOrder : quiz.position,
                        thumbnail: quiz.links.thumbnail,
                        type: 'quiz',
                    });
                }
            });
        }

        return items;
    }, [allQuizzes, allVideos, allGroups, groupById]);

    return (!groupById || isLoading || !isMounted)
        ? <Loader />
        : <Container fluid className="mainsquare">
        
            <Row className="grouptitle">
                <div className='headercontainer'><span id='titletext'>{groupById?.name}</span>
                    {groupById?.isInteractive && 
                <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip>Cet espace immersif est entièrement interactif dans le casque</Tooltip>}>
                            <span className="groupIcon"><i className="fas fa-hand-sparkles"></i></span>
                        </OverlayTrigger>}
                { AuthorizationChecker.isAdmin() && groupById?.private && 
                <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip>Espace immersif privé</Tooltip>}>
                            <span className="groupIcon"><i className="fas fa-lock"></i></span>
                </OverlayTrigger>}
                { AuthorizationChecker.isAdmin() &&  groupById?.isSpecial && 
                <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip>Espace immersif au contenu automatisé</Tooltip>}>
                            <span className="groupIcon"><i className="fas fa-magic"></i></span>
                </OverlayTrigger>}
                { AuthorizationChecker.isAdmin() && groupById?.releaseDate && isDateLaterThanToday(groupById.releaseDate) && 
                        <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip>Cet espace immersif n'est pas encore publié</Tooltip>}>
                            <span className="groupIcon"><i className="fas fa-hourglass"></i></span>
                        </OverlayTrigger> }
                </div>
                {backButtonComponent}
            </Row>
            { groupById?.pedagogicModules?.length > 0 &&
            <Row className="ml-1">
                <p><strong><i className="nav-icon icon-books"/> Associé {groupById?.pedagogicModules?.length > 1 ? "aux modules pédagogiques" : "au module pédagogique"} : </strong>
                { groupById?.pedagogicModules?.map((pedagogicModule, i) => { return <Button variant="light" as={Link} to={pedagogicModuleSlugs.readOne.replace(':uniqueId', pedagogicModule.uniqueId)} key={pedagogicModule.uniqueId} className="ml-1">{pedagogicModule.name}</Button>})
                }
                </p>
            </Row>}
            <Row className="editButtons">
                {canDownloadThumbnailsContent && items?.length > 0 &&
                    (generateThumbnailContentGroupPDF
                    // TODO : Remove `items={items}` when back will return only content accessible by user connected
                    ? <DownloadGroupThumbnailsContentPDF group={groupById} items={items} buttonText="Vignettes de l'espace immersif"
                            fileName={"vignettes-espace-immersif-" + groupById?.name?.toLowerCase()} />
                        : <Button variant="primary" onClick={() => { setGenerateThumbnailContentGroupPDF(true) }} disabled={!groupById}>
                            <i className="fas fa-download mr-1" />Vignettes de l'espace immersif
                        </Button>)}
                {canDownloadContent 
                    && (generateSubGroupsPDF
                    ? <DownloadGroupsVideosPDF title={"Contenu de l'espace immersif \"" + groupById?.name + "\""} buttonText="Contenu de l'espace immersif"
                        fileName={"tableau-contenu-videos-" + groupById?.name?.toLowerCase()}
                            videos={allVideos?.sort((a, b) => a.videoName.localeCompare(b.videoName))} 
                        groups={groupsToDisplay} />
                        : <Button variant="primary" onClick={() => {setGenerateSubGroupsPDF(true)}} 
                            disabled={allVideos?.length === 0}>
                        <i className="fas fa-download mr-1" />Contenu de l'espace immersif
                        </Button>)}
                {AuthorizationChecker.hasUpdateRights('groups') &&
                    <Button variant="secondary" size="sm" as={Link} to={groupsSlugs.update.replace(':uniqueId', uniqueId)}><i className="fas fa-edit"/> Éditer</Button>
                }
                {AuthorizationChecker.hasDeleteRights('groups') && (
                    (hasItemsContent || IsInGroups || +groupById?.idHeadset === 0) ? 
                    <OverlayTrigger
                        placement="right"
                        overlay={ <Tooltip>Ce groupe ne peut pas être supprimé car il a du contenu et/ou est lié à un autre groupe.</Tooltip> }>
                        <span style={{width: "fit-content"}}><Button variant="danger" disabled size="sm" ><i className="fas fa-trash-alt"/> Supprimer</Button></span>
                    </OverlayTrigger>
                    :<Button variant="danger" size="sm" onClick={createDeleteModal}><i className="fas fa-trash-alt"/> Supprimer</Button>
                )}
            </Row>

            

            {IsInGroups &&
                <Row className="parentRow">
                    <div className="parentImages">
                        {groupById?.groups.map(parent => {
                            return (
                                <div className="parentImage" key={parent.uniqueId}>
                                    <img src={parent.links?.thumbnail} alt="parent vignette" onClick={() => { if (groupsSlugs.readOne) navigate(groupsSlugs.readOne.replace(':uniqueId', parent.uniqueId)) }} />
                                    <i className="fas fa-chevron-down"></i>
                                </div>
                            )
                        })}
                    </div>
                </Row>
            }

            {groupById.isPreviewOnly && groupById?.links?.videoPreview &&
                <Alert variant="info mt-3">
                    {groupById.isInteractive
                        ? "Ce groupe est entièrement interactif dans le casque, voici un aperçu vidéo"
                        : "Le contenu de ce groupe n'est pas affichable, voici un aperçu vidéo"}
                </Alert>
            }
            <Row className="headsetView">
                {groupById.isPreviewOnly && groupById?.links?.videoPreview ?
                    <div className="group360">
                        <video controls style={{ width: '100%', height: '100%', objectFit: 'cover' }}>
                            <source src={groupById.links?.videoPreview} type={"video/mp4"} />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    : <GroupDisplayContentComponent group={groupById} setHasItemsCallback={setHasItemsContent} />
                }
            </Row>

            <Row className="infoSection">
                <div className="groupInfoCard">
                    <div className="groupThumbnail">
                        <img src={groupById?.links?.thumbnail} alt="vignette de l'espace immersif" />
                    </div>
                    <div className="groupInfos">
                        <span className="text-muted" dangerouslySetInnerHTML={{ __html: groupById?.description }}></span>
                        {directAccessCode}
                        { AuthorizationChecker.isAdmin() && (
                            <>
                        {publicationDate}
                        {updateDate}
                        {groupProducers}
                        {footerFiles}
                        </>
                        )
                        }
                    </div>
                </div>
            </Row>

            {!groupById.isPreviewOnly && groupById?.links?.videoPreview &&
                <>
                    <Alert variant="info mt-3">Voici un aperçu vidéo de l'affichage du contenu dans le casque</Alert>
                    <div className="group360">
                        <video controls style={{ width: '100%', height: '100%', objectFit: 'cover' }}>
                            <source src={groupById.links?.videoPreview} type={"video/mp4"} />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </>
            }
            {
                AuthorizationChecker.isAdmin() && groupById.isPreviewOnly && <>
                    <h4><i class="fas fa-user-cog" /> Vue pour les admins du contenu</h4>
                    <GroupDisplayContentComponent group={groupById} setHasItemsCallback={setHasItemsContent} />
                </>
            }

            {modalComponent}
        </Container>;
}

export default GroupDetailPage;