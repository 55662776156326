import React, { useState, useMemo } from "react";

import { Container, Row, ToggleButtonGroup, ToggleButton, Alert } from 'react-bootstrap';

import ActivitiesNumbers from '../../../../components/activities/ActivitiesNumbers';
import VideoPlayGraph from '../../../../components/activities/VideoPlayGraph';

import './ClientStatsTabComponent.scss';

const ClientStatsTabComponent = (props) => {
    const { clientUniqueId } = props;

    const [isFetching, setIsFetching] = useState(false);
    const [isFetchingNumbers, setIsFetchingNumbers] = useState(false);
    const [oneMonth, setOneMonth] = useState(true);

    const clientsUniqueIdAsArray = useMemo(() => [clientUniqueId], []);

    const toggleOneMonth = [
        { label: "30 derniers jours", value: true, defaultChecked: true },
        { label: "Depuis le début", value: false, defaultChecked: false }
    ];

    return (
        <Container fluid>
            <Row>
                <Alert variant="warning">Seules les données issues de casques mis à jour sont disponibles, si un casque n'a pas été connecté à notre système depuis, ses usages ne sont pas inclus dans les chiffres présentés.</Alert>
            </Row>
            <Row className="row-allcenter">
                <div className="d-flex align-items-center justify-content-between w-auto">
                    <ToggleButtonGroup className='py-2' type="radio" name="oneMonthCheck" id="oneMonthCheckSelect" value={oneMonth}
                        onChange={() => setOneMonth((prev) => !prev)}>
                        {toggleOneMonth.map(data =>
                            <ToggleButton value={data.value} key={data.label} id={data.label} checked={oneMonth} className='mb-0' disabled={isFetching || isFetchingNumbers}>
                                {data.label}
                            </ToggleButton>
                        )}
                    </ToggleButtonGroup>
                </div>
            </Row>
            <Row className="row-allcenter">
                <ActivitiesNumbers clientsUniqueId={clientsUniqueIdAsArray} setIsFetching={setIsFetching} isFetching={isFetching} oneMonth={oneMonth} />
            </Row>
            <Row className="row-allcenter video-graphs">
                <VideoPlayGraph clientsUniqueId={clientsUniqueIdAsArray} setIsFetching={setIsFetchingNumbers} isFetching={isFetchingNumbers} oneMonth={oneMonth} />
            </Row>
        </Container>
    )
}

export default ClientStatsTabComponent;